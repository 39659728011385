import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectOption from "../../../Components/SelectOption/selectOption";
import TranslationService from "../../../Services/translationService";
import Textarea from "../../../Components/Inputs/textArea";
import { ANSWER_CASE_TYPES, ERROR_KEY, NUMBER_KEY, STRING_KEY } from "../../../Constants/mainKeys";
import RichTextEditor from "../Components/RichTextEditor";
import { withRouter } from "react-router-dom";
import { useCallback } from "react";
import { addButtonSpinner, addPartialViewSpinner, removeButtonSpinner, removePartialViewSpinner } from "../../../Store/Actions/spinner";
import AlertService from './../../../Services/alertService';
import uuid from "react-uuid";
import ApiService from "../../../Services/apiService";
import Input from "../../../Components/Inputs/input";
import { SUCCESS_KEY } from "../../../Constants/requestKeys";
import { TR_CREATE_MESSAGE_KEY, TR_UPDATE_MESSAGE_KEY } from "../../../Constants/translationKeys";
import SubmitButton from "../../../Components/SubmitButton/submitButton";

const ShortAnswer = (props) => {

  const dispatch = useDispatch();
  const answerMaxLength = 1000;
  const nameMaxLength = 200;
  const markMaxCount = 1000;
  const buttonSpinnerId = uuid();

  const grades = [100, 90, 83.33, 80, 75, 70, 66.66, 60, 50, 40, 33.33, 30, 25, 20, 16.66, 14.28, 12.5, 11.11, 10, 5];
  const answerCaseTypes = ANSWER_CASE_TYPES;

  const translations = useSelector(state => state.translation.translations);

  const { questionId } = props.match.params;
  const { questionType } = props.match.params;

  const [name, setName] = useState("");
  const [mark, setMark] = useState(1);
  const [text, setText] = useState(!questionId ? "" : null);
  const [feedback, setFeedback] = useState(!questionId ? "" : null);
  const [questionsGroups, setQuestionsGroups] = useState([]);
  const [questionsGroupId, setQuestionsGroupId] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [hints, setHints] = useState([]);
  const [isCaseSensitive, setIsCaseSensitive] = useState(0);


  const [translationService, setTranslationService] = useState(null);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (questionId) {
      getQuestionById(questionId);
    } else {
      addAnswers();
      addHints(3);
    }
    getQuestionGroups();
  }, []);

  const getQuestionById = questionId => {
    const spinnerId = uuid();
    setPartialViewSpinner(spinnerId)
    ApiService.getQuestionById(+questionId).then(response => {
      const questionData = { ...response.data };
      if (!questionData.questionAnswers || (questionData.questionAnswers && !questionData.questionAnswers.length)) {
        addAnswers();
      } else {
        setAnswers(questionData.questionAnswers)
      }
      if (!questionData.questionHints || (questionData.questionHints && !questionData.questionHints.length)) {
        addHints(1);
      } else {
        setHints(questionData.questionHints);
      }
      questionData.text && setText(questionData.text);
      questionData.name && setName(questionData.name);
      questionData.questionsGroupId && setQuestionsGroupId(questionData.questionsGroupId);
      setFeedback(questionData.feedback ? questionData.feedback : "");
      setMark(questionData.mark ? questionData.mark : 1);
      setIsCaseSensitive(+questionData.isCaseSensitive);
      extractPartialViewSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId));
  }

  const getQuestionGroups = () => {
    const spinnerId = uuid();
    setPartialViewSpinner(spinnerId);
    ApiService.getQuestionsGroups().then(response => {
      const questionsGroups = [...response.data];
      setQuestionsGroups(questionsGroups);
      extractPartialViewSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const onAnswerChange = (event, answerIndex, type, maxLength = null) => {
    if (maxLength && type === STRING_KEY && (maxLength < event.target.value.length)) { return; }
    setIsInvalidSubmit(false);
    if (typeof event.target.value === type || typeof +event.target.value === type) {
      const answer = { ...answers[answerIndex] };
      answer[event.target.name] = event.target.value;
      const answersCopy = [...answers];
      answersCopy[answerIndex] = answer;
      setAnswers(answersCopy);
    }
  };

  const onEditorAnswerChange = (event, answerIndex, name) => {
    const newRichText = event.editor.getData();
    const answer = { ...answers[answerIndex] };
    answer[name] = newRichText;
    const answersCopy = [...answers];
    answersCopy[answerIndex] = answer;
    setAnswers(answersCopy);
  }


  const onNumberChange = (event, cb, maxLength) => {
    if (event.target.value.includes("e") || event.target.value.includes(".")) { return false };
    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && +event.target.value >= 0 && +event.target.value <= maxLength)) {
      cb(event.target.value);
    }
  }

  const onEditorChange = (event, cb) => {
    const newRichText = event.editor.getData();
    cb(newRichText);
  }

  const onTextChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return false; }
    cb(event.target.value);
  };

  const addAnswers = () => {
    const answersCopy = [...answers];
    for (let i = 1; i <= 1; i++) {
      answersCopy.push({
        text: '',
        markPercent: '5',
        feedback: ''
      });
    }
    setAnswers(answersCopy);
  }


  const addHints = hintsCount => {
    const hintsCopy = [...hints];
    for (let i = 0; i < hintsCount; i++) {
      hintsCopy.push({
        text: '',
      });
    }
    setHints(hintsCopy);
  }

  const onEditorHineChange = (event, hintIndex, name) => {
    const newRichText = event.editor.getData();
    const hint = { ...hints[hintIndex] };
    hint[name] = newRichText;
    const hintsCopy = [...hints];
    hintsCopy[hintIndex] = hint;
    setHints(hintsCopy);
  };

  const onSelectOptionChange = (event, cb) => {
    if (event.target.value === '' || typeof +event.target.value === NUMBER_KEY) {
      cb(event.target.value)
    }
  };

  const scrollToInvalidFieldPosition = () => {
    setTimeout(() => {
      let firstInvalidControl =
        document.querySelector(".select-fail") ||
        document.querySelector(".is-invalid") ||
        document.querySelector(".error-bordered") ||
        document.querySelector(".error-bordered-2") ||
        document.querySelector(".fail");
      firstInvalidControl && window.scrollTo({
        top: firstInvalidControl?.getBoundingClientRect().top + document.documentElement.scrollTop - 100,
        behavior: "smooth"
      });
    }, 300);
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    const filledAnswers = answers.filter(data => {
      if (data.text) {
        return data.text && data.text.trim();
      }
    });
    if (
      !text.trim().length ||
      !questionsGroupId ||
      (!filledAnswers.length || (filledAnswers.length && filledAnswers.length < 1))
    ) {
      setIsInvalidSubmit(true);
      scrollToInvalidFieldPosition();
      return false;
    }
    const form = {
      answers: answers.filter(answer => answer && answer.text && answer.text.trim().length),
      feedback,
      hints: hints.filter(hint => hint && hint.text && hint.text.trim().length),
      isCaseSensitive: !!+isCaseSensitive,
      name,
      text,
      mark: +mark,
      questionType: +questionType,
      questionsGroupId: +questionsGroupId,
    }
    if (questionId) form.id = +questionId;
    setButtonSpinner(buttonSpinnerId);
    (questionId ? ApiService.questionUpdate(form) : ApiService.questionCreate(form)).then(() => {
      AlertService.alert(SUCCESS_KEY, translationService.translate(`${questionId ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`));
      extractButtonSpinner(buttonSpinnerId);
      props.goBack();
    }).catch(error => getFail(error, buttonSpinnerId));
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setPartialViewSpinner = useCallback(spinner => {
    dispatch(addPartialViewSpinner(spinner));
  }, []);

  const extractPartialViewSpinner = useCallback(spinner => {
    dispatch(removePartialViewSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractPartialViewSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  const filledAnswers = answers.filter(data => data.text);

  return translationService ? <form onSubmit={onSubmit}>
    <div className="row">
      <div className="col-lg-4 col-md-6 col-12">
        <SelectOption
          value={questionsGroupId}
          name="questionsGroupId"
          label={`${translationService.translate("TR_QUESTIONS_GROUP")} *`}
          defaultValue=" "
          isInvalidSubmit={isInvalidSubmit}
          items={questionsGroups}
          changed={(event) => onSelectOptionChange(event, setQuestionsGroupId)}
        />
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        <Input
          id="name"
          type="text"
          name="name"
          inputClassName="pr--5"
          value={name}
          fieldLength={nameMaxLength}
          labelValue={translationService.translate("TR_NAME")}
          onChange={event => onTextChange(event, setName, nameMaxLength)}
        />
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        <Input
          type="number"
          id="mark"
          name="mark"
          value={mark}
          isInvalidSubmit={isInvalidSubmit}
          labelValue={`${translationService.translate("TR_DEFAULT_MARK")} *`}
          onChange={(event) => onNumberChange(event, setMark, markMaxCount)}
        />
      </div>
      {
        typeof text === STRING_KEY ?
          <div className="col-12 mb-3">
            <RichTextEditor
              value={text}
              labelValue={`${translationService.translate("TR_QUESTION")} *`}
              isInvalidSubmit={isInvalidSubmit}
              onEditorChange={(event) => onEditorChange(event, setText)}
            />
          </div>
          : null
      }
      {
        typeof feedback === STRING_KEY ?
          <div className="col-12 mb-3">
            <RichTextEditor
              value={feedback}
              labelValue={`${translationService.translate("TR_GENERAL_FEEDBACK")}`}
              onEditorChange={(event) => onEditorChange(event, setFeedback)}
            />
          </div>
          : null
      }


      <div className="col-lg-6 col-md-6 col-12">
        <SelectOption
          name="isCaseSensitive"
          value={isCaseSensitive}
          label={translationService.translate('TR_SELECT_ANSWER_TYPE_SENSITIVITY')}
          items={answerCaseTypes}
          changed={(event) => onSelectOptionChange(event, setIsCaseSensitive)}
        />
      </div>
      <div className="col-12">
        <div className="content-sub-title d-flex align-items-center justify-content-between">
          <h3>{translationService.translate("TR_ANSWERS")}</h3>
        </div>
        {
          isInvalidSubmit && filledAnswers.length < 1
            ? <div className="mb-2">
              <small className="fail">
                {translationService.translate('TR_QUESTION_ANSWERS_COUNT_REQUIRED_MSG').replace('{0}', '1')}
              </small>
            </div>
            : null
        }
        {
          answers && answers.map((answer, index) => {
            const { text, feedback, markPercent } = answer;
            return <div key={index} className={`p-2 mt-3 light-gray-background ${isInvalidSubmit && (!text || !text.trim()) && index < 1 ? "is-invalid error-bordered" : ""}`}>
              <div className="col-12">
                <p className="mb-1">{`${translationService.translate('TR_TEXT')} *`}</p>
                <Textarea
                  rows="2"
                  name="text"
                  value={text}
                  max={answerMaxLength}
                  inputClassName={isInvalidSubmit && (!text || !text.trim()) && index < 1 ? "is-invalid error-bordered" : null}
                  onChange={event => onAnswerChange(event, index, STRING_KEY, answerMaxLength)}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <SelectOption
                  returnItem={true}
                  hasPercentSign={true}
                  name="markPercent"
                  label={translationService.translate('TR_GRADE_PERCENT')}
                  value={markPercent}
                  items={grades}
                  changed={event => onAnswerChange(event, index, NUMBER_KEY)}
                />
              </div>
              <div className="col-12">
                <RichTextEditor
                  value={feedback}
                  labelValue={`${translationService.translate('TR_FEEDBACK')}`}
                  onEditorChange={event => onEditorAnswerChange(event, index, "feedback")}
                />
              </div>
            </div>
          })
        }
        <div className="text-right mt-3">
          <button type="button" className="mindalay--btn-secondary mindalay--btn-small" onClick={addAnswers}>
            <i className="fas fa-plus"></i>
            {translationService.translate("TR_ADD_ANSWER")}
          </button>
        </div>
      </div>
      <div className="col-12"><hr /></div>
      <div className="col-12">
        <div className="content-sub-title d-flex align-items-center justify-content-between">
          <h3>{translationService.translate("TR_HINTS")}</h3>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          {
            hints && hints.map((hint, index) => {
              const { text } = hint;
              return <div key={index} className="col-12">
                <RichTextEditor
                  value={text}
                  blockClassName='my-2'
                  labelValue={`${translationService.translate("TR_HINT")} ${index + 1}`}
                  onEditorChange={event => onEditorHineChange(event, index, "text")}
                />
              </div>
            })
          }
        </div>
      </div>
      <div className="col-12">
        <div className="text-right mt-2">
          <button type="button" className="mindalay--btn-secondary mindalay--btn-small" onClick={() => addHints(1)}>
            <i className="fas fa-plus"></i>
            {translationService.translate("TR_ADD_HINT")}
          </button>
        </div>
      </div>
      <div className="col-12 mt-4 text-right">
        <hr />
        <SubmitButton
          id={questionId}
          spinnerId={buttonSpinnerId}
        />
      </div>
    </div>
  </form> : null;
}

export default withRouter(ShortAnswer)
