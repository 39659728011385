import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import uuid from "react-uuid";
import { ERROR_KEY } from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import {
	removePartialViewSpinner,
	addPartialViewSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "./../../Services/apiService";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import MainService from "../../Services/mainService";
import RolesService from "../../Services/rolesService";

export default function Info() {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const [storageMaxSize, setStorageMaxSize] = useState(null);
	const [usedStorageSize, setUsedStorageSize] = useState(null);
	const [addedUsersCount, setAddedUsersCount] = useState(null);
	const [usersMaxCount, setUsersMaxCount] = useState(null);
	const [roleService, setRoleService] = useState(null);
	const [organizationInnerBalanse, setOrganizationInnerBalans] = useState(null);
	const { role, user } = useSelector(state => state.user);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		MainService.isJson(role) &&
			setRoleService(new RolesService(JSON.parse(role)));
	}, [role]);

	useEffect(() => {
		getOrgAdminInfo();
	}, []);

	useEffect(() => {
		if (
			roleService &&
			roleService.checkRole &&
			roleService.checkRole("org_inner_payment_view") &&
			user &&
			user.isAdmin &&
			user.innerPayment
		) {
			getOrganizationInnerPaymentContract();
		}
	}, [roleService, user]);

	const getOrganizationInnerPaymentContract = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getOrganizationInnerPaymentContract()
			.then(response => {
				setOrganizationInnerBalans(response.data);
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getOrgAdminInfo = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getOrgAdminInfo()
			.then(response => {
				if (response.data) {
					response.data.addedUsersCount &&
						setAddedUsersCount(response.data.addedUsersCount);
					response.data.storageMaxSize &&
						setStorageMaxSize(response.data.storageMaxSize);
					response.data.usedStorageSize &&
						setUsedStorageSize(response.data.usedStorageSize);
					response.data.usersMaxCount &&
						setUsersMaxCount(response.data.usersMaxCount);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	};

	const bytesToSize = bytes => {
		var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		if (bytes == 0) return "0 Byte";
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
	};

	return translationService ? (
		<div className="container mt-4">
			<div className="row">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_INFO_PAGE")}
					</h2>
					<PageInfoBlock
						pageTitle={translationService.translate("TR_INFO_PAGE")}
						pageName="info"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row">
				{organizationInnerBalanse && (
					<div className="col-12">
						<div className="content-sub-title">
							<h3 className="course-title">
								{translationService.translate("TR_YOUR_CURRENT_BALANCE")}
							</h3>
						</div>
						<div className="mindalay--overview-container m-0 mx-0 ">
							<div className="mindalay--overview-header d-lg-flex align-items-lg-center justify-content-lg-between border-none">
								{
									<div className="mindalay--overview-header-wrapper">
										<div className="mindalay--overview-total-wrapper">
											<div className="mindalay--overview-total">
												<p className="mindalay--overview-info-title">
													{translationService.translate("TR_BALANCE")}
												</p>
												<span className="mindalay--overview-info-value">
													{organizationInnerBalanse.balanceAsString
														? organizationInnerBalanse.balanceAsString
														: `${organizationInnerBalanse.balance}$`}
												</span>
												<br />
											</div>
											<span></span>
											<div className="mindalay--overview-this-month">
												<p className="mindalay--overview-info-title">
													{translationService.translate("TR_POTENTIAL_BALANCE")}
												</p>

												<span className="mindalay--overview-info-value">
													{organizationInnerBalanse.potentialBalanceAsString
														? organizationInnerBalanse.potentialBalanceAsString
														: ` ${organizationInnerBalanse.potentialBalance}$`}
												</span>
												<br />
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				)}
				<div className="col-lg-6 col-sm-12">
					<div className="content-sub-title">
						<h3 className="course-title">
							{translationService.translate("TR_STORAGE_SIZE")}
						</h3>
					</div>
					<div>
						<div className="d-flex justify-content-between">
							{usedStorageSize ? (
								<p>
									<b>{bytesToSize(usedStorageSize)}</b>
								</p>
							) : (
								<p>
									<b>0</b>
								</p>
							)}
							{storageMaxSize ? (
								<p>
									<b>{bytesToSize(storageMaxSize)}</b>
								</p>
							) : (
								<p>
									<b>0</b>
								</p>
							)}
						</div>
						<div className="line-loader">
							<div
								className="line-loader-item"
								style={{
									width: `${(+usedStorageSize / +storageMaxSize) * 100}%`,
								}}
							/>
						</div>
					</div>
				</div>
				<div className="col-lg-6 col-sm-12">
					<div className="content-sub-title">
						<h3 className="course-title">
							{translationService.translate("TR_USERS")}
						</h3>
					</div>
					<div>
						<div className="d-flex justify-content-between">
							<p>
								<b>{addedUsersCount}</b>
							</p>
							<p>
								<b>{usersMaxCount}</b>
							</p>
						</div>
						<div className="line-loader">
							<div
								className="line-loader-item"
								style={{
									width: `${(+addedUsersCount / +usersMaxCount) * 100}%`,
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : null;
}
