import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import {
	addPageSpinner,
	removeButtonSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import { BACK_URL_KEY, ERROR_KEY, MINDALAY_KEY } from "../../Constants/mainKeys";
import ApiService from "../../Services/apiService";
import { withRouter } from "react-router-dom";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import NodataImage from "../../assets/images/illustrations/nodata.svg";
import Parser from 'html-react-parser';
import { Helmet } from "react-helmet";

const About = props => {
	const { pageId } = props.match.params;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [pageContent, setPageContent] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getPageById();
	}, [language, pageId]);

	const getPageById = () => {
		if (!pageId) { return false; }
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		ApiService.getPageById(+pageId)
			.then(response => {
				if (response.data) { setPageContent({ ...response.data }) }
				dispatch(removePageSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const goBack = () => {
		if (localStorage.getItem(BACK_URL_KEY)) {
			props.history.push(localStorage.getItem(BACK_URL_KEY));
		} else {
			props.history.push(`/${language}`);
		}
		localStorage.removeItem(BACK_URL_KEY);
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePageSpinner(spinnerId));
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
	};

	return (translationService ? pageContent ? <div className="container mt-4">
		{
			pageContent.name ?
				<Helmet>
					<title>{`${translationService.translate(pageContent.name)} | ${MINDALAY_KEY}`}</title>
				</Helmet>
				: null
		}
		<div className='footer-page'>
			<div className="row">
				<div className="col-12">
					<h2 className="content-title p-0">
						<button
							title={translationService.translate("TR_BACK")}
							onClick={goBack}
						>
							<ArrowBackSvg />
						</button>
						{translationService.translate(pageContent.name)}
					</h2>
					<hr />
					<div className="col-12">
						{
							pageContent.pageContentResponses && pageContent.pageContentResponses.length ?
								pageContent.pageContentResponses.map(pageContentResponse => {
									return Parser(pageContentResponse.fullContent)
								})
								: <div className="no-data-container">
									<div className="no-data-wrapper">
										<p>{translationService.translate("TR_NO_DATA")}</p>
										<img src={NodataImage} alt="/" />
									</div>
								</div>
						}
					</div>
				</div>
			</div>
		</div>
	</div >
		: <div className="no-data-container">
			<div className="no-data-wrapper">
				<p>{translationService.translate("TR_NO_DATA")}</p>
				<img src={NodataImage} alt="/" />
			</div>
		</div>
		: null
	);
};
export default withRouter(About);
