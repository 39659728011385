import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/js/bootstrap.bundle";
import "react-credit-cards/es/styles-compiled.css";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./documents.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { BrowserRouter } from "react-router-dom";
import watchers from "./Store/Sagas";
import reducer from "./Store/Reducers";
// import { API_URL_KEY, IDENTITY_API_URL_KEY } from './Constants/apiKeys';

// var meta = document.querySelector("meta");
// meta.content = `default-src 'self' 'unsafe-inline'
//   cdn.datatables.net
//   ka-f.fontawesome.com
//   fonts.googleapis.com
//   js.stripe.com
//   fonts.gstatic.com
//   ipapi.co
//   cdn.ckeditor.com
//   ws:
//   ${API_URL_KEY}
//   ${IDENTITY_API_URL_KEY}
//   https://*.paypal.com
//   bid.g.doubleclick.net
//   ;

//   script-src 'self' 'unsafe-inline'
//   cdn.datatables.net
//   kit.fontawesome.com
//   ka-f.fontawesome.com
//   code.jquery.com
//   gitcdn.github.io
//   js.stripe.com
//   cdn.ckeditor.com
//   ${API_URL_KEY}
//   ${IDENTITY_API_URL_KEY}
//   https://*.paypal.com
//   bid.g.doubleclick.net
//   ;

//   img-src 'self' 'unsafe-inline'
//   ${API_URL_KEY}
//   ${IDENTITY_API_URL_KEY}
//   cdn.ckeditor.com
//   data:
//   ;
// `;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const saga = createSagaMiddleware();
const store = createStore(
	reducer,
	composeEnhancers(applyMiddleware(thunk, saga)),
);

saga.run(watchers);
window.Storage = store;

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root"),
);

reportWebVitals();
