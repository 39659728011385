import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import * as moment from "moment";
import Parser from "html-react-parser";
import { compose } from "redux";
import {
	ERROR_KEY,
	FILL_BALANCE_KEY,
	NUMBER_KEY,
	RATINGS,
	REDIRECT_URL_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../Constants/mainKeys";
import {
	COURSE_KEY,
	FINANCES_KEY,
	ORGANIZATION_ADMIN_KEY,
	PASS_COURSE_KEY,
} from "../../Constants/urlKeys";
import TranslationService from "../../Services/translationService";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import MainService from "../../Services/mainService";
import ShowMoreText from "react-show-more-text";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import Sections from "../../Pages/CourseSections/sections";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import PosterImage from "./../../assets/images/video-poster.png";
import LowBalanceModal from "./lowBalanceModal";
import ReactPlayer from "react-player";

class CourseView extends Component {
	ratings = RATINGS;

	state = {
		courseId: +this.props.match.params.courseId,
		courseData: null,
		sectionsViewCount: 10,
		sections: [],
		whatYouWillLearn: [],
		requirements: [],
		targetStudents: [],
		translationService: null,
		defaultValue: { label: "", value: "" },
		ongoingVideoId: null,
		totalBalance: 0,
		balanceAsString: null,
		isShowLowBalanceModal: false,
		fillBalance: 0,
	};

	componentDidMount() {
		const { courseId } = this.state;
		this.setTranslations();
		courseId && this.getCourseById(courseId);
		if (this.props.user && this.props.user.innerPayment) {
			this.getBalanceAsOrgUser();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		this.setTranslations();
		if (prevProps.match.params.courseId !== this.props.match.params.courseId) {
			this.setState({ courseId: +this.props.match.params.courseId }, () => {
				this.getCourseById(this.state.courseId);
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
			JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	getBalanceAsOrgUser = () => {
		const spinnerId = uuid();
		this.props.addPartialViewSpinner(spinnerId);
		ApiService.getBalanceAsOrgUser()
			.then(response => {
				if (response && response.data) {
					this.setState({
						totalBalance: response.data.balance1 ? response.data.balance1 : 0,
						balanceAsString: response.data.balanceAsString,
					});
				}
				this.props.removePartialViewSpinner(spinnerId);
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	getCourseById = courseId => {
		const spinnerId = uuid();
		this.props.addPartialViewSpinner(spinnerId);
		ApiService.getPublishedCourseByIdByOrgUser(courseId)
			.then(response => {
				if (response && response.data) {
					var courseData = { ...response.data };
					this.setState({
						courseData,
						courseId,
						sections: courseData.sections,
						whatYouWillLearn: courseData.whatYouWillLearn
							? Object.values(JSON.parse(courseData.whatYouWillLearn))
							: [],
						requirements: courseData.requirements
							? Object.values(JSON.parse(courseData.requirements))
							: [],
						targetStudents: courseData.targetStudents
							? Object.values(JSON.parse(courseData.targetStudents))
							: [],
					});
				} else {
					this.props.history.push(`/${this.props.language}`);
				}
				this.props.removePartialViewSpinner(spinnerId);
			})
			.catch(error => {
				this.getFail(error, spinnerId);
			});
	};

	convertSecondsToHoursAndMinutes = d => {
		const { translationService } = this.state;
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);
		var hDisplay =
			h > 0
				? h + `${translationService.translate("TR_SHORT_HOUR")}` + `${"\u00A0"}`
				: "";
		var mDisplay =
			m > 0
				? m +
				`${translationService.translate("TR_SHORT_MINUTE")}` +
				`${"\u00A0"}`
				: "";
		var sDisplay =
			s > 0
				? s +
				`${translationService.translate("TR_SHORT_SECOND")}` +
				`${"\u00A0"}`
				: "";
		return hDisplay + mDisplay + sDisplay;
	};

	payLessThanFullPriceForCourse = innerPaymentId => {
		// if your balance >= current course price
		const { translationService, courseId } = this.state;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			this.props.addPartialViewSpinner(spinnerId);
			ApiService.payLessThanFullPriceForCourse(innerPaymentId)
				.then(() => {
					this.getCourseById(courseId);
					this.props.removePartialViewSpinner(spinnerId);
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_COURSE_PURCHASED_SUCCESSFULLY"),
					);
				})
				.catch(error => this.getFail(error, spinnerId));
		});
	};

	payFullPriceForCourse = innerPaymentId => {
		// if your balance >= current course price
		const { translationService, courseId } = this.state;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			this.props.addPartialViewSpinner(spinnerId);
			ApiService.payFullPriceForCourse(innerPaymentId)
				.then(() => {
					this.getCourseById(courseId);
					this.props.removePartialViewSpinner(spinnerId);
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_COURSE_PURCHASED_SUCCESSFULLY"),
					);
				})
				.catch(error => this.getFail(error, spinnerId));
		});
	};

	showLowBalanceModal = amount => {
		this.setState({
			fillBalance: amount,
			isShowLowBalanceModal: true,
		});
	};

	hideLowBalanceModal = () => {
		this.setState({
			fillBalance: 0,
			isShowLowBalanceModal: false,
		});
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		error && error.respcode === 1 && this.props.history.push(`/${this.props.language}`);
		spinnerId && this.props.removePartialViewSpinner(spinnerId);
	};

	render() {
		const {
			translationService,
			courseData,
			whatYouWillLearn,
			sections,
			courseId,
			sectionsViewCount,
			ongoingVideoId,
			totalBalance,
			isShowLowBalanceModal,
			fillBalance,
		} = this.state;

		const { language, user } = this.props;

		var topicsCount = 0;
		if (courseData && courseData.sections) {
			courseData.sections.forEach(section => {
				if (section.topics) {
					topicsCount = topicsCount + section.topics.length;
				}
			});
		}

		return translationService && courseData ? (
			<section>
				{isShowLowBalanceModal ? (
					<LowBalanceModal
						isLarge={true}
						title={translationService.translate("TR_LOW_BALANCE")}
						cancel={this.hideLowBalanceModal}>
						<div>
							<p className="d-inline">
								{translationService.translate("TR_LOW_BALANCE_INFO")}
							</p>
							<Link
								to={`/${language}/${ORGANIZATION_ADMIN_KEY}/${FINANCES_KEY}`}
								onClick={() => {
									localStorage.setItem(FILL_BALANCE_KEY, fillBalance);
									localStorage.setItem(
										REDIRECT_URL_KEY,
										window.location.pathname,
									);
								}}>
								{translationService.translate("TR_REPLENISH")}
							</Link>
							.
						</div>
					</LowBalanceModal>
				) : null}
				<div className="container course-info-container m-section">
					<div className="row ">
						<div className="col-12">
							{courseData.categories?.length ? (
								<Auxiliary>
									<div className="d-flex align-items-center">
										<h2 className="content-title p-0">
											<Link
												to="#"
												title={translationService.translate("TR_BACK")}
												onClick={() => window.history.back()}>
												<ArrowBackSvg />
											</Link>
										</h2>
										<nav aria-label="breadcrumb" className="w-100">
											<ol className="breadcrumb mb-0">
												{courseData.categories.map(category => (
													<li key={category.id} className="breadcrumb-item">
														<Link to="#" className="cursor-default">
															{category.name}
														</Link>
													</li>
												))}
											</ol>
										</nav>
									</div>
									<hr />
								</Auxiliary>
							) : null}
						</div>
						<div className="col-xl-3 col-lg-6 col-12">
							<div className="background box-shadow-4 course-main-info">
								{courseData.presentationFilePath ? (
									<div className="course-media-wrapper cursor-pointer">
										<video
											className="m-0 cursor-pointer video-tag"
											controls
											poster={PosterImage}
											autoPlay={true}
											onPointerDown={() =>
												this.setState({ ongoingVideoId: courseData.id })
											}>
											{ongoingVideoId === courseData.id ? (
												<source
													src={courseData.presentationFilePath}
													type="video/mp4"
												/>
											) : null}
										</video>
									</div>
								) : courseData.videoLink ? (
									<div
										className="course-media-wrapper w-100"
										style={{ height: "200px" }}>
										<ReactPlayer
											className="course-video-link"
											url={courseData.videoLink}
											config={{
												youtube: {
													playerVars: { showinfo: 0, controls: 1 },
												},
												facebook: {
													appId: "12345",
												},
											}}
										/>
									</div>
								) : (
									<div
										className="course-image mb-2"
										style={{
											backgroundImage: `url(${courseData.imagePath})`,
										}}></div>
								)}
								<div className="course-card-body px-3 py-2">
									<div className="course-price-wrapper">
										{
											<p className="course-new-price">
												{courseData.discount === 100
													? `${translationService.translate("TR_FREE")}`
													: courseData.cost}
											</p>
										}
										{courseData.discount ? (
											<div className="d-flex align-items-center justify-content-between">
												{courseData.oldCost || courseData.oldCost === 0 ? (
													<p className="course-old-price">
														{courseData.oldCost}
													</p>
												) : null}
												<p className="discount">
													{courseData.discount}%{" "}
													<span>{translationService.translate("TR_OFF")}</span>
												</p>
											</div>
										) : null}
									</div>
									{courseData.isBelongToStudent ? (
										<div className="mt-2">
											{courseData.isBlocked && courseData.blockMessage ? (
												<div className="mb-2">
													<strong>
														{translationService.translate(
															"TR_COURSE_IS_BLOCKED",
														)}
													</strong>
													<p className="mt-2">{courseData.blockMessage}</p>
												</div>
											) : null}
											{courseData.isPaused ? (
												<div className="mb-2">
													<strong>
														{translationService.translate(
															"TR_COURSE_IS_PAUSED",
														)}
													</strong>
													<p className="mt-2">
														<span className="d-block">
															{translationService.translate("TR_START_DATE")}
														</span>
														<small>
															{moment(
																MainService.convertUTCDateToLocalDate(
																	new Date(courseData.pauseStartDate),
																),
															).format("ll (HH:mm)")}
														</small>
													</p>
													<p className="mt-2">
														<span className="d-block">
															{translationService.translate("TR_END_DATE")}
														</span>
														<small>
															{moment(
																MainService.convertUTCDateToLocalDate(
																	new Date(courseData.pauseStartDate),
																),
															).format("ll (HH:mm)")}
														</small>
													</p>
												</div>
											) : null}
											{courseData.withPayment ? (
												<Auxiliary>
													{courseData.paymentInfo ? (
														courseData.paymentInfo.studentPaymentInfo ? (
															courseData.paymentInfo.studentPaymentInfo
																.isPaid ? (
																<strong className="d-block mb-2 text-success">
																	{translationService.translate(
																		"TR_COURSE_BOUGHT",
																	)}
																</strong>
															) : (
																<strong className="d-block mb-2 secondary-color">
																	{translationService.translate(
																		"TR_COURSE_NOT_PURCHASED_YET",
																	)}
																</strong>
															)
														) : null
													) : null}
													{courseData.paymentInfo ? (
														<Auxiliary>
															{courseData.paymentInfo.price ? (
																<div className="d-flex justify-content-between mb-2">
																	<strong className="">
																		{translationService.translate("TR_PRICE")}{" "}
																	</strong>
																	<small>
																		{courseData.paymentInfo.priceAsString}
																	</small>
																</div>
															) : null}
															{courseData.paymentInfo.paymentCount ? (
																<div className="d-flex justify-content-between mb-2">
																	<strong className="">
																		{translationService.translate(
																			"TR_PAYMENT_COUNT",
																		)}{" "}
																	</strong>
																	<small>
																		{courseData.paymentInfo.paymentCount}
																	</small>
																</div>
															) : null}

															{courseData.paymentInfo.perPaymentAmount ? (
																<div className="d-flex justify-content-between mb-2">
																	<strong className="">
																		{translationService.translate(
																			"TR_PER_PAYMENT_AMOUNT",
																		)}{" "}
																	</strong>
																	<small>
																		{
																			courseData.paymentInfo
																				.perPaymentAmountAsString
																		}
																	</small>
																</div>
															) : null}
															{courseData.paymentInfo.period ? (
																<div className="d-flex justify-content-between mb-2">
																	<strong className="">
																		{translationService.translate("TR_PERIOD")}{" "}
																	</strong>
																	<small>
																		{courseData.paymentInfo.period}{" "}
																		{translationService.translate(
																			courseData.paymentInfo.period > 1
																				? "TR_DAYS"
																				: "TR_DAY",
																		)}
																	</small>
																</div>
															) : null}
															{!courseData.paymentInfo.studentPaymentInfo ? (
																<Auxiliary>
																	<Link
																		to="#"
																		className="mindalay--btn-dark mindalay--btn-link mb-2 w-100"
																		onClick={() => {
																			if (
																				totalBalance >=
																				courseData.paymentInfo.price ||
																				totalBalance >=
																				courseData.paymentInfo
																					?.studentPaymentInfo?.leftToPay
																			) {
																				// if your balance >= current course price
																				this.payFullPriceForCourse(
																					courseData.paymentInfo.innerPaymentId,
																				);
																			} else {
																				this.showLowBalanceModal(
																					courseData.paymentInfo.price -
																					totalBalance,
																				);
																			}
																		}}>
																		{translationService.translate(
																			"TR_PAY_COURSE",
																		)}
																	</Link>
																	{courseData.paymentInfo.paymentCount > 1 ? (
																		<Link
																			to="#"
																			className="mindalay--btn-dark mindalay--btn-link mb-2 w-100"
																			onClick={() => {
																				if (
																					totalBalance >=
																					courseData.paymentInfo
																						.perPaymentAmount
																				) {
																					// if your balance >= current course perPaymentAmount
																					this.payLessThanFullPriceForCourse(
																						courseData.paymentInfo
																							.innerPaymentId,
																					);
																				} else {
																					this.showLowBalanceModal(
																						courseData.paymentInfo
																							.perPaymentAmount - totalBalance,
																					);
																				}
																			}}>
																			{translationService.translate(
																				"TR_PAY_POERIOD",
																			)}
																		</Link>
																	) : null}
																</Auxiliary>
															) : (
																<Auxiliary>
																	{!courseData.paymentInfo.studentPaymentInfo
																		.isPaid ? (
																		<div>
																			{typeof courseData.paymentInfo
																				.studentPaymentInfo.dueAmount ===
																				NUMBER_KEY ? (
																				<div className="d-flex justify-content-between mb-2">
																					<strong>
																						{translationService.translate(
																							"TR_DUE_AMOUNT",
																						)}
																					</strong>
																					<small>
																						{
																							courseData.paymentInfo
																								.studentPaymentInfo
																								.dueAmountAsString
																						}
																					</small>
																				</div>
																			) : null}
																			{courseData.paymentInfo.studentPaymentInfo
																				.leftToPay ? (
																				<div className="d-flex justify-content-between mb-2">
																					<strong className="">
																						{translationService.translate(
																							"TR_LEFT_TO_PAY",
																						)}{" "}
																					</strong>
																					<small>
																						{
																							courseData.paymentInfo
																								.studentPaymentInfo
																								.leftToPayAsString
																						}
																					</small>
																				</div>
																			) : null}
																			{courseData.paymentInfo.studentPaymentInfo
																				.lastPaymentDate ? (
																				<div className="mb-2">
																					<strong className="d-block">
																						{translationService.translate(
																							"TR_LAST_PAYMENT_DATE",
																						)}
																					</strong>
																					<small>
																						{moment(
																							MainService.convertUTCDateToLocalDate(
																								new Date(
																									courseData.paymentInfo.studentPaymentInfo.lastPaymentDate,
																								),
																							),
																						).format("ll (HH:mm)")}
																					</small>
																				</div>
																			) : null}
																			{courseData.paymentInfo.studentPaymentInfo
																				.futurePaymentDate ? (
																				<div className="mb-2">
																					<strong className="d-block">
																						{translationService.translate(
																							"TR_NEXT_PAYMENT_DATE",
																						)}
																					</strong>
																					<small>
																						{moment(
																							MainService.convertUTCDateToLocalDate(
																								new Date(
																									courseData.paymentInfo.studentPaymentInfo.futurePaymentDate,
																								),
																							),
																						).format("ll (HH:mm)")}
																					</small>
																				</div>
																			) : null}
																			{courseData.paymentInfo &&
																				courseData.paymentInfo
																					?.studentPaymentInfo ? (
																				<Link
																					to={`#`}
																					className="mindalay--btn-dark mindalay--btn-link my-3 w-100"
																					// onClick={() => {
																					// 	if (
																					// 		totalBalance >=
																					// 			courseData.paymentInfo.price ||
																					// 		totalBalance >=
																					// 			courseData.paymentInfo
																					// 				?.studentPaymentInfo.leftToPay
																					// 	) {
																					// 		// if your balance >= current course price
																					// 		this.payFullPriceForCourse(
																					// 			courseData.paymentInfo
																					// 				.innerPaymentId,
																					// 		);
																					// 	} else if (
																					// 		courseData?.paymentInfo?.price &&
																					// 		courseData?.paymentInfo
																					// 			?.studentPaymentInfo
																					// 			?.perPaymentAmount
																					// 	) {
																					// 		this.showLowBalanceModal(
																					// 			courseData.paymentInfo.price -
																					// 				courseData?.paymentInfo
																					// 					?.studentPaymentInfo
																					// 					?.perPaymentAmount,
																					// 		);
																					// 	} else {
																					// 		this.showLowBalanceModal(
																					// 			courseData.paymentInfo.price -
																					// 				totalBalance,
																					// 		);
																					// 	}
																					// }}

																					// onClick={() => {
																					// 	if (totalBalance >= courseData.paymentInfo?.studentPaymentInfo.leftToPay) {
																					// 		// if your balance >= leftToPay
																					// 		this.payFullPriceForCourse(courseData.paymentInfo.innerPaymentId);
																					// 	} else if (courseData?.paymentInfo?.price && courseData?.paymentInfo?.studentPaymentInfo?.perPaymentAmount) {
																					// 		this.showLowBalanceModal(courseData.paymentInfo.price - courseData?.paymentInfo?.studentPaymentInfo?.perPaymentAmount);
																					// 	} else {
																					// 		this.showLowBalanceModal(courseData.paymentInfo.price - totalBalance);
																					// 	}
																					// }}
																					onClick={() => {
																						if (totalBalance >= courseData.paymentInfo?.studentPaymentInfo.leftToPay) {
																							this.payFullPriceForCourse(courseData.paymentInfo.innerPaymentId);
																						} else {
																							this.showLowBalanceModal(courseData.paymentInfo?.studentPaymentInfo.leftToPay - totalBalance);
																						}
																					}}
																				>
																					{translationService.translate(
																						"TR_PAY_COURSE",
																					)}
																				</Link>
																			) : null}
																			{courseData.paymentInfo.studentPaymentInfo
																				.leftToPay >
																				courseData.paymentInfo
																					.studentPaymentInfo.dueAmount &&
																				courseData.paymentInfo?.studentPaymentInfo
																					.dueAmount ? (
																				<Link
																					to={`#`}
																					className="mindalay--btn-dark mindalay--btn-link my-3 w-100"
																					onClick={() => {
																						if (
																							totalBalance >=
																							courseData.paymentInfo
																								.studentPaymentInfo.dueAmount
																						) {
																							// if your balance >= current course dueAmount
																							this.payLessThanFullPriceForCourse(
																								courseData.paymentInfo
																									.innerPaymentId,
																							);
																						} else {
																							this.showLowBalanceModal(
																								courseData.paymentInfo
																									.studentPaymentInfo.leftToPay,
																							);
																						}
																					}}>
																					{translationService.translate(
																						"TR_PAY_POERIOD",
																					)}
																				</Link>
																			) : null}
																		</div>
																	) : null}
																</Auxiliary>
															)}
														</Auxiliary>
													) : null}
												</Auxiliary>
											) : null}
											{!courseData.isBlocked && !courseData.isPaused ? (
												<Auxiliary>
													{courseData.isStarted && !courseData.isTimeExpired ? (
														<Link
															to={`/${language}/${PASS_COURSE_KEY}/${courseId}/${courseData.studentCourseId}`}
															className="mindalay--btn-secondary-outline mindalay--btn-link mb-2 w-100">
															{translationService.translate(
																"TR_CONTINUE_LEARNING",
															)}
														</Link>
													) : null}
													{!courseData.isStarted &&
														!courseData.isTimeExpired ? (
														<Link
															to={`/${language}/${PASS_COURSE_KEY}/${courseId}/${courseData.studentCourseId}`}
															className="mindalay--btn-secondary-outline mindalay--btn-link mb-2 w-100">
															{translationService.translate(
																"TR_START_LEARNING",
															)}
														</Link>
													) : null}
												</Auxiliary>
											) : null}
										</div>
									) : null}

									<hr />
									<div className="course-property mt-2">
										<p>
											{translationService.translate("TR_THIS_COURSE_INCLUDES")}:
										</p>
										<ul className="list-group mt-1">
											{courseData.isLifeTime ? (
												<li className="list-group-item">
													<i className="fas fa-infinity mr-1"></i>{" "}
													{translationService.translate(
														"TR_FILL_LIFETIME_ACCESS",
													)}
												</li>
											) : null}
											{courseData.discount &&
												courseData.discountEndDate &&
												!courseData.isBelongToUser ? (
												<li className="list-group-item">
													<i className="fas fa-percent"></i>{" "}
													{translationService.translate("TR_UP_TO")}
													<span className="ml-1">
														{moment(
															MainService.convertUTCDateToLocalDate(
																new Date(courseData.discountEndDate),
															),
														).format("YYYY-MM-DD HH:mm")}
													</span>
												</li>
											) : null}
											{courseData.allVideoDuration ? (
												<li className="list-group-item">
													<i className="far fa-image"></i>
													{this.convertSecondsToHoursAndMinutes(
														courseData.allVideoDuration,
													)}
												</li>
											) : null}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-9 col-lg-6 col-12 mt-4">
							<div className="course-info-wrapper">
								<div className="course-general-info">
									<h1 className="mb-2">{courseData.name}</h1>
									<div className="d-flex mt-2 icon-wrapper align-items-center">
										{courseData.modificationDate ? (
											<Auxiliary>
												<p>
													<i className="fas fa-sync mr-1"></i>
													{translationService.translate("TR_LAST_UPDATED")}
													<span className="ml-1">
														{moment(
															MainService.convertUTCDateToLocalDate(
																new Date(courseData.modificationDate),
															),
														).format("YYYY-MM-DD")}
													</span>
												</p>
											</Auxiliary>
										) : null}
									</div>
								</div>
								{whatYouWillLearn?.length ? (
									<Auxiliary>
										<hr />
										<div className="course-what-we-learn p-3 light-blue-background">
											<div className="row">
												<div className="col-12">
													<h3 className="title">
														{translationService.translate(
															"TR_WHAT_YOU_WILL_LEARN_DESC",
														)}
													</h3>
												</div>
												<div className="col-12">
													<div className="row">
														{whatYouWillLearn.map((description, index) => {
															return description ? (
																<div
																	key={index}
																	className="col-lg-6 col-md-6 col-6">
																	<div className="d-flex chech-icon-list">
																		<i className="fas fa-check"></i>
																		<p>{description}</p>
																	</div>
																</div>
															) : null;
														})}
													</div>
												</div>
											</div>
										</div>
									</Auxiliary>
								) : null}
								{courseData.description ? (
									<Auxiliary>
										<hr />
										<div className="course-view-description">
											<h3 className="title">
												{translationService.translate("TR_DESCRIPTION")}
											</h3>
											<div>
												<ShowMoreText
													lines={2}
													more={translationService.translate("TR_SHOW_MORE")}
													less={translationService.translate("TR_LESS")}
													className="content-css"
													anchorClass="my-anchor-css-class"
													expanded={false}
													truncatedEndingComponent={"... "}>
													<span className="course-description-block">
														{Parser(courseData.description)}
													</span>
												</ShowMoreText>
											</div>
										</div>
									</Auxiliary>
								) : null}
								{sections?.length ? (
									<Auxiliary>
										<hr />
										<div className="course-content">
											<h3 className="title">
												{translationService.translate("TR_CURICULUMN")}
											</h3>
											{
												<div>
													<ul className="pl-0 d-flex">
														{courseData.sections.length ? (
															courseData.sections.length <= 1 ? (
																<li>{`${courseData.sections.length
																	} ${translationService.translate(
																		"TR_SECTION",
																	)}`}</li>
															) : (
																<li>{`${courseData.sections.length
																	} ${translationService.translate(
																		"TR_SECTIONS",
																	)}`}</li>
															)
														) : null}
														,{"\u00A0"}
														{topicsCount ? (
															topicsCount <= 1 ? (
																<li>{`${topicsCount} ${translationService.translate(
																	"TR_TOPIC",
																)}`}</li>
															) : (
																<li>{`${topicsCount} ${translationService.translate(
																	"TR_TOPICS",
																)}`}</li>
															)
														) : null}
														,{"\u00A0"}
														<li>
															{this.convertSecondsToHoursAndMinutes(
																courseData.allVideoDuration,
															)}
															{translationService.translate("TR_TOTAL_LENGTH")}
														</li>
													</ul>
												</div>
											}
											{
												<Sections
													sections={sections}
													isEditMode={false}
													isBelongToUser={courseData.isBelongToUser}
													sectionsViewCount={sectionsViewCount}
												/>
											}
											{sectionsViewCount < sections.length ? (
												<button
													type="button"
													className="mindalay--btn-default w-100 mt-4"
													onClick={() =>
														this.setState({
															sectionsViewCount: sectionsViewCount + 10,
														})
													}>
													{translationService.translate("TR_SHOW_MORE")}
												</button>
											) : null}
										</div>
									</Auxiliary>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</section>
		) : null;
	}
}
const mapStateToProps = state => ({
	buttonSpinners: state.spinner.buttonSpinners,
	language: state.language.language,
	user: state.user.user,
	translations: state.translation.translations,
});

const mapDispatchToProps = {
	addPartialViewSpinner,
	removePartialViewSpinner,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(CourseView);
