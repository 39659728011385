import React, { Component } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import Input from "../../Components/Inputs/input";
import { WEBINAR_SCREEN_URL_KEY } from "../../Constants/apiKeys";
import { USER_KEY, WEBINAR_DATA_KEY } from "../../Constants/mainKeys";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ApiService from "../../Services/apiService";
import MainService from "../../Services/mainService";
import TranslationService from "../../Services/translationService";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import moment from 'moment';
import NukaCarousel from 'nuka-carousel';
import Parser from 'html-react-parser';

const isBase64 = (str) => {
  if (str === '' || str.trim() === '') { return false; }
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}

class JoinOneTimeWebinar extends Component {

  state = {
    name: "",
    translationService: null,
    isInvalidSubmit: '',
    webinarId: isBase64(this.props.match?.params?.webinarId) ? +atob(this.props.match?.params?.webinarId) : null,
    webinarCalendarId: isBase64(this.props.match?.params?.webinarCalendarId) ? +atob(this.props.match?.params?.webinarCalendarId) : null,
    lecturerUserId: isBase64(this.props.match?.params?.lecturerUserId) ? +atob(this.props.match?.params?.lecturerUserId) : null,
    webinarType: 2,
    currentWebinarData: {},
    showPageContent: false,
    webinarName: "",
    errorMsg: "",
    nameMaxLength: 100,
    pageContentResponses: [],
  }

  componentDidMount() {
    this.setTranslations();
    const { webinarId, webinarCalendarId, lecturerUserId, webinarType, showPageContent } = this.state;
    if (webinarId && webinarCalendarId && webinarType && lecturerUserId) {
      this.setState({
        currentWebinarData: {
          webinarId,
          webinarCalendarId,
          lecturerUserId,
          webinarType
        }
      })
    }
    if (isBase64(this.props.match?.params?.webinarId) && !showPageContent) {
      this.interval = setInterval(this.checkOneTimeWebinarDate, 10000);
    }
    this.checkOneTimeWebinarDate();
    this.getPageContent();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if (this.state.showPageContent) {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  checkOneTimeWebinarDate = () => {
    const { webinarId, errorMsg } = this.state;
    if (webinarId && !errorMsg) {
      const spinnerId = uuid();
      ApiService.checkOneTimeWebinarDate(webinarId).then(response => {
        const webinarData = { ...response.data };
        const webinarName = response.data.name;
        this.setState({ webinarName, webinarData });
        if (response.data && response.data?.webinarCalendar && response.data?.webinarCalendar?.inProcess && response.data?.webinarCalendar?.realStartTime) {
          this.setState({ showPageContent: true });
        }
      }).catch(error => this.getFail(spinnerId, error));
    }
  }

  getPageContent = () => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getOnetimeWebinarPageContent().then(response => {
      if (response.data) {
        this.setState({ pageContentResponses: response.data?.pageContentResponses })
      }
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(spinnerId, error));
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { user } = this.props;
    const spinnerId = uuid();
    const { name, currentWebinarData, webinarCalendarId } = this.state;
    if (!user && !localStorage.getItem("webinar-member")) {
      if (!name.trim() || name.trim().length < 2 || !webinarCalendarId) {
        this.setState({ isInvalidSubmit: true });
        return;
      }
      this.props.addPageSpinner(spinnerId);
      ApiService.joinToWebinar(webinarCalendarId, name).then(response => {
        if (response.data) {
          const data = { ...response.data };
          localStorage.setItem(USER_KEY, JSON.stringify(data));
          localStorage.setItem("webinar-member", JSON.stringify(data));
          localStorage.setItem(WEBINAR_DATA_KEY, JSON.stringify(currentWebinarData));
          window.location.href = WEBINAR_SCREEN_URL_KEY;
        }
        this.props.removePageSpinner(spinnerId);
      }).catch(error => this.getFail(spinnerId, error));
    } else if (user) {
      localStorage.setItem(WEBINAR_DATA_KEY, JSON.stringify(currentWebinarData));
      window.location.href = WEBINAR_SCREEN_URL_KEY;
    } else if (localStorage.getItem("webinar-member")) {
      localStorage.setItem(USER_KEY, localStorage.getItem("webinar-member"));
      localStorage.setItem(WEBINAR_DATA_KEY, JSON.stringify(currentWebinarData));
      window.location.href = WEBINAR_SCREEN_URL_KEY;
    }
  }

  getFail = (spinnerId, error) => {
    error && this.setState({ errorMsg: error.respmess })
    this.props.removePageSpinner(spinnerId);
  };

  render() {
    const { user } = this.props;
    const { translationService, name, isInvalidSubmit, showPageContent, webinarName, errorMsg, nameMaxLength, webinarData, pageContentResponses } = this.state;

    return (
      translationService ? <section className="m-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="section-title">{translationService.translate("TR_JOIN_VIDEO_CONFERENCE")}</h2>
            </div>
          </div>
          {
            webinarData ?
              <div className="row">
                <div className="col-12">
                  <div className="mt-4">
                    <div className="box-shadow-4 mindalay--consultation-container flex-col-reverse d-flex">
                      <div className="header-right-side d-flex align-items-center">
                        <div className="consultation-user-info">
                          <div className="d-flex align-items-center">
                            <h3 className="consultation-user-name gray-color">{webinarData?.lecturerName}</h3>
                            <b className="gmt-time text-dark ml-2">({translationService.translate("TR_CREATOR")})</b>
                          </div>
                          <div className="d-flex align-items-center">
                            <small className="gmt-time text-dark">
                              {`${translationService.translate("TR_START_DATE")}:`}
                            </small>
                            <small className="secondary-color ml-2">{`${moment(MainService.convertUTCDateToLocalDate(new Date(webinarData?.webinarCalendar.startTime))).format("LLL")}`}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null
          }
          <div className="row mt-4">
            <div className="col-lg-8 col-md-8 col-12">
              {
                errorMsg && !webinarData ?
                  <p className="red-color mt-3">{errorMsg}</p>
                  : null
              }
              {
                !errorMsg && webinarData ?
                  showPageContent ?
                    <Auxiliary>
                      {
                        !user && !localStorage.getItem("webinar-member")
                          ? <p className="mt-3 mb-3">{translationService.translate("TR_DEAR_PARTICIPANT_INFO").replace('{0}', webinarName ? '"' + webinarName + '"' : translationService.translate("TR_VIDEO_CONFERENCE"))}</p>
                          : user
                            ? <p className="mt-3 mb-3">{translationService.translate("TR_VIDEO_CONFERENCE_INFO_FOR_CURRENT_USER").replace('{0}', user.fullName).replace('{1}', webinarName ? '"' + webinarName + '"' : translationService.translate("TR_VIDEO_CONFERENCE"))}</p>
                            : <p className="mt-3 mb-3">{translationService.translate("TR_VIDEO_CONFERENCE_INFO_FOR_CURRENT_USER").replace('{0}', JSON.parse(localStorage.getItem("webinar-member"))?.fullName).replace('{1}', webinarName ? '"' + webinarName + '"' : translationService.translate("TR_VIDEO_CONFERENCE"))}</p>
                      }
                      <form onSubmit={this.onSubmit}>
                        {
                          !user && !localStorage.getItem("webinar-member") ?
                            <Input
                              type="text"
                              id="name"
                              name="name"
                              value={name}
                              fieldLength={nameMaxLength}
                              isInvalidSubmit={isInvalidSubmit}
                              isInvalidField={isInvalidSubmit && name.trim().length < 2}
                              labelValue={`${translationService.translate("TR_ENTER_YOUR_NAME")} *`}
                              onChange={(event) => this.onChange(event, nameMaxLength)}
                            />
                            : null
                        }
                        <button type="submit" className="mindalay--btn-default">{translationService.translate("TR_GO_TO_CONFERENCE_ROOM")}</button>
                      </form>
                    </Auxiliary>
                    : user ? <p className="mt-3 mb-3">{translationService.translate("TR_WAIT_WEBINAR_START_INFO").replace('{0}', user ? user.fullName : translationService.translate("TR_PARTICIPANT")).replace('{1}', webinarName ? '"' + webinarName + '"' : "")}</p>
                      : localStorage.getItem("webinar-member")
                        ? <p className="mt-3 mb-3">{translationService.translate("TR_WAIT_WEBINAR_START_INFO").replace('{0}', localStorage.getItem("webinar-member") ? JSON.parse(localStorage.getItem("webinar-member"))?.fullName : translationService.translate("TR_PARTICIPANT")).replace('{1}', webinarName ? '"' + webinarName + '"' : "")}</p>
                        : null
                  : null
              }
            </div>
            {
              !webinarData && !errorMsg ?
                <div className="col-12">
                  <p className="red-color mt-3">{translationService.translate("TR_NOT_VALID_VIDEO_CONFERENCE")}</p>
                </div>
                : null
            }
            <div className="col-12"> <hr /></div>
            {
              pageContentResponses && pageContentResponses.length ?
                <section className="container light-blue-background cover-image">
                  <NukaCarousel
                    autoplay={pageContentResponses > 1 ? true : false}
                    wrapAround={pageContentResponses > 1 ? true : false}
                    autoplayInterval={4000}
                    speed={1000}
                  >
                    {
                      pageContentResponses.map(pageContent => {
                        return <div key={pageContent.id} className="d-flex align-items-center justify-content-around">
                          <div className="cover-text-wrapper">
                            <h1 className="cover-title"><span>{pageContent.name}</span></h1>
                            <hr />
                            <span>{Parser(pageContent.fullContent)}</span>
                          </div>
                          <img src={pageContent.imagePath} alt="/" />
                        </div>
                      })
                    }
                  </NukaCarousel>
                </section>
                : null
            }
          </div>
        </div>
      </section> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  user: state.user.user
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner,
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinOneTimeWebinar)

