import { deleteCookie } from "../../Services/cookieService";
import {
  REMOVE_LOGIN_ERROR_MESSAGE_REQUEST,
  AUTH_LOGOUT_REQUEST
} from "../../Constants/reduxRequestKeys";
import {
  USER_KEY,
  TOKEN_KEY,
  CURRENT_COURSE,
  WEBINAR_DATA_KEY,
  SHOPPING_CART_COUNT_KEY,
  WISH_LIST_COUNT_KEY,
  PURE_LAYOUT_BACK_KEY,
  WISH_LIST_PRODUCTS_IDS_KEYS,
  SHOPPING_CART_PRODUCTS_IDS_KEYS,
  RETURN_URL_KEY,
  LANGUAGES_KEY,
  FILL_BALANCE_KEY,
  REFRESH_TOKEN_KEY,
  REMEMBER_ME_KEY,
  REMEMBER_ME_ITEM_KEY,
  MENU_KEY,
  CONSULTATION_CONTRACT_BACK_KEY,
  FAQ_KEY,
  FAQ_PROPERTY_ID_KEY,
  CURRENCY_KEY,
  BACK_URL_KEY,
  CURRENCY_SYMBOL_KEY,
} from "../../Constants/mainKeys";
import {
  AUTH_SUCCESS,
  CURRENT_USER_SUCCESS,
  AUTH_FAIL,
  CURRENT_USER_UPDATE_SUCCESS,
  USER_REGISTRATION_SUCCESS,
  REGISTRATION_FAIL,
  GET_PAGES_FROM_ORGANIZATION_USER_SUCCESS,
} from '../../Constants/reduxKeys';
import MainService from "../../Services/mainService";

const initialState = {
  hasUser: MainService.isJson(localStorage.getItem(USER_KEY)) && localStorage.getItem(TOKEN_KEY) ? true : false,
  token: localStorage.getItem(TOKEN_KEY),
  refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
  user: MainService.isJson(localStorage.getItem(USER_KEY)) ? JSON.parse(localStorage.getItem(USER_KEY)) : null,
  // role: localStorage.getItem(USER_KEY) && MainService.isJson(localStorage.getItem(USER_KEY)) ? JSON.parse(localStorage.getItem(USER_KEY)).roles : '',
  role: localStorage.getItem(USER_KEY) ? atob(JSON.parse(localStorage.getItem(USER_KEY)).roles) : [],
  loginErrorMessage: '',
  registrationErrorMessage: '',
  userRegistered: false,
  availablePages: localStorage.getItem(MENU_KEY) ? JSON.parse(localStorage.getItem(MENU_KEY)) : [],
  isShowChooseUserTypeModal: false,
};

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case AUTH_SUCCESS:
      localStorage.setItem(TOKEN_KEY, action.payload.token.data.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, action.payload.refreshToken);
      return {
        ...state,
        token: action.payload.token.data.accessToken,
        refreshToken: action.payload.refreshToken
      };
    case AUTH_FAIL:
      return {
        ...state,
        loginErrorMessage: action.payload,
      };
    case REMOVE_LOGIN_ERROR_MESSAGE_REQUEST:
      return {
        ...state,
        loginErrorMessage: ''
      };
    case AUTH_LOGOUT_REQUEST:
      deleteCookie(REMEMBER_ME_KEY);
      deleteCookie(REMEMBER_ME_ITEM_KEY);
      localStorage.removeItem(WISH_LIST_PRODUCTS_IDS_KEYS);
      localStorage.removeItem(SHOPPING_CART_PRODUCTS_IDS_KEYS);
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(CURRENT_COURSE);
      localStorage.removeItem(WEBINAR_DATA_KEY);
      localStorage.removeItem(SHOPPING_CART_COUNT_KEY);
      localStorage.removeItem(WISH_LIST_COUNT_KEY);
      localStorage.removeItem(PURE_LAYOUT_BACK_KEY);
      localStorage.removeItem(RETURN_URL_KEY);
      localStorage.removeItem(LANGUAGES_KEY);
      localStorage.removeItem(FILL_BALANCE_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      localStorage.removeItem(MENU_KEY);
      localStorage.removeItem(CONSULTATION_CONTRACT_BACK_KEY);
      localStorage.removeItem(FAQ_KEY);
      localStorage.removeItem(FAQ_PROPERTY_ID_KEY);
      localStorage.removeItem(BACK_URL_KEY);
      localStorage.removeItem(CURRENCY_KEY);
      localStorage.removeItem(CURRENCY_SYMBOL_KEY);
      return {
        ...state,
        token: null,
        user: null,
        hasUser: false,
        role: ''
      };
    case CURRENT_USER_SUCCESS:
      localStorage.setItem(USER_KEY, JSON.stringify(action.payload));
      localStorage.setItem(SHOPPING_CART_COUNT_KEY, action.payload.shoppingCartCount);
      localStorage.setItem(WISH_LIST_COUNT_KEY, action.payload.wishListCount);
      return {
        ...state,
        user: action.payload,
        hasUser: true,
        role: action.payload.roles ? atob(action.payload.roles) : ""
      };
    case USER_REGISTRATION_SUCCESS:
      return {
        ...state,
        userRegistered: true
      };
    case REGISTRATION_FAIL:
      return {
        ...state,
        registrationErrorMessage: action.payload.respmess
      }
    case CURRENT_USER_UPDATE_SUCCESS:
      localStorage.setItem(USER_KEY, JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload
      };
    case GET_PAGES_FROM_ORGANIZATION_USER_SUCCESS:
      localStorage.setItem(MENU_KEY, JSON.stringify(action.payload))
      return {
        ...state,
        availablePages: action.payload
      };
      break;
  }
  return state;
};

export default reducer;