import React, { Component } from "react";
import { connect } from "react-redux";
import AuthInput from "../../Components/Inputs/authInput";
import { withRouter } from "react-router-dom";
import ActionButton from "../../Components/ActionButton/actionButton";
import TranslationService from "../../Services/translationService";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import { addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";
import { ERROR_KEY, SUCCESS_KEY } from "../../Constants/mainKeys";
import MainService from "../../Services/mainService";
import { addPageSpinner, removePageSpinner } from './../../Store/Actions/spinner';
import { compose } from "redux";
import { LOGIN_KEY } from "../../Constants/urlKeys";


class ResetPassword extends Component {

    state = {
        form: {
            password: '',
            confirmPassword: '',
            resetPasswordkey: this.props.match.params.guid || null
        },
        translationService: null,
        isInvalidSubmit: false,
        spinnerId: uuid(),
        guid: this.props.match?.params?.guid || "",
        checkUserByGuid: false,
    };

    componentDidMount() {
        this.setTranslations();
        this.checkUserByGuid();
    }

    componentDidUpdate() {
        this.setTranslations();
    }

    setTranslations = () => {
        if (!this.state.translationService && this.props.translations) {
            this.setState({ translationService: new TranslationService(this.props.translations) });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.translations
            && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
            this.setState({ translationService: new TranslationService(nextProps.translations) });
        }
        return true;
    }

    checkUserByGuid = () => {
        const { guid } = this.state;
        const spinnerId = uuid();
        this.props.addPageSpinner(spinnerId);
        ApiService.checkUserByGuid(guid).then(response => {
            this.props.removePageSpinner(spinnerId);
            response && this.setState({
                checkUserByGuid: true
            });
        }).catch(error => this.getFail(error, spinnerId));
    }

    getFail = (message, spinnerId) => {
        message && AlertService.alert((AlertService.checkMessageType(message.respcode) || ERROR_KEY), message);
        spinnerId && this.props.removePageSpinner(spinnerId);
        this.props.history.push(`/${this.props.language}/${LOGIN_KEY}`)
    }

    onChange = (event, maxLength = null) => {
        if (maxLength && (maxLength < event.target.value.length)) { return; }
        this.setState(prevState => ({
            ...prevState,
            isInvalidPassword: null,
            isInvalidPassword: false,
            isInvalidconfirmPassword: false,
            mismatchPasswords: false,
            form: {
                ...prevState.form,
                [event.target.name]: event.target.value,
            }
        }));
    }

    checkFieldValidation = (fieldName, type) => {
        const { password, confirmPassword } = this.state.form;
        if (type === "password" || type === "confirmPassword") {
            const isValid = MainService.isValidField(fieldName, type);
            this.changeIsinvalidFieldName(isValid, type === "password" ? "isInvalidPassword" : "isInvalidconfirmPassword");
            if ((password && confirmPassword) && (password !== confirmPassword)) {
                this.changeIsinvalidFieldName(false, "mismatchPasswords");
            } else this.changeIsinvalidFieldName(true, "mismatchPasswords");
        }
    }

    changeIsinvalidFieldName = (isValid, fieldName) => {
        if (!isValid) {
            this.setState({ [fieldName]: true });
        } else {
            this.setState({ [fieldName]: false });
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { spinnerId, isInvalidPassword, mismatchPasswords, isInvalidconfirmPassword, checkUserByGuid, translationService } = this.state;
        var { password, confirmPassword, resetPasswordkey } = this.state.form;
        const { language } = this.props;

        if ((password && confirmPassword && checkUserByGuid) && (!isInvalidPassword && !mismatchPasswords && !isInvalidconfirmPassword)) {
            password = btoa(password);
            confirmPassword = btoa(confirmPassword);
            this.props.addButtonSpinner(spinnerId);
            ApiService.resetPassword({ password, confirmPassword, resetPasswordkey }).then(() => {
                this.props.removeButtonSpinner(spinnerId);
                AlertService.alert(SUCCESS_KEY, translationService.translate("TR_PASSWORD_CHANGED_SUCCESSFULLY"));
                this.props.history.push(`/${language}/${LOGIN_KEY}`);
            }).catch(error => this.submitFail(spinnerId, error));
        } else {
            this.setState({ isInvalidSubmit: true })
        }
    };

    submitFail = (spinnerId, errors) => {
        this.props.removeButtonSpinner(spinnerId);
        errors && AlertService.alert((AlertService.checkMessageType(errors.respcode) || ERROR_KEY), errors);
    };

    render() {
        const { translationService, isInvalidSubmit, spinnerId, isInvalidPassword, isInvalidconfirmPassword, mismatchPasswords } = this.state;
        const { password, confirmPassword } = this.state.form;

        return translationService ? <div className="col-12">
            <div className="authorization-form content-background" style={{ maxWidth: "500px" }}>
                <p className="authorization-form-title">{translationService.translate("TR_RESET_PASSWORD")}</p>
                <hr />
                <p>{translationService.translate("TR_RESET_PASSWORD_INFO")}</p>
                <form onSubmit={this.onSubmit}>
                    <AuthInput
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        isInvalidSubmit={isInvalidSubmit}
                        inputClassName={`${(isInvalidSubmit && (!password || password !== confirmPassword)) ? "error-border" : null}`}
                        placeholder={`${translationService.translate('TR_NEW_PASSWORD')} *`}
                        onChange={this.onChange}
                        onBlur={() => this.checkFieldValidation(password, "password")}
                    />
                    {
                        isInvalidPassword ?
                            <small className="red-color">{translationService.translate("TR_PASSWORD_INFO")}</small>
                            : null
                    }
                    <AuthInput
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmPassword}
                        isInvalidSubmit={isInvalidSubmit}
                        inputClassName={`${(isInvalidSubmit && (!password || password !== confirmPassword)) ? "error-border" : null}`}
                        placeholder={`${translationService.translate('TR_CONFIRM_NEW_PASSWORD')} *`}
                        onChange={this.onChange}
                        onBlur={() => this.checkFieldValidation(confirmPassword, "confirmPassword")}
                    />
                    {
                        isInvalidconfirmPassword ?
                            <small className="red-color">{translationService.translate("TR_PASSWORD_INFO")}</small>
                            : null
                    }
                    {
                        mismatchPasswords ?
                            <div className="mt-2"><small className="red-color">{translationService.translate("TR_PASSWORD_MISMATCH")}</small></div>
                            : null
                    }
                    <div className="my-3">
                        <ActionButton
                            spinnerId={spinnerId}
                            type="submit"
                            className="mindalay--btn-dark w-100 position-relative"
                            name={translationService.translate("TR_SAVE")}
                        />
                    </div>
                    {/* <div className="social-icons-wrepper">
                        <p className="text-main mb-3">{translationService.translate("TR_OR_SIGN_IN_USING")}</p>
                        <div className="social-icons d-flex">
                            <Link to="#"><img src="/assets/icon/facebook.svg" alt="/" /></Link>
                            <Link to="#"><img src="/assets/icon/twitter.svg" alt="/" /></Link>
                            <Link to="#"><img src="/assets/icon/google-plus.svg" alt="/" /></Link>
                            <Link to="#"><img src="/assets/icon/linkedin.svg" alt="/" /></Link>
                        </div>
                    </div> */}
                </form>
            </div>
        </div> : null;
    }

}
const mapStateToProps = state => ({
    language: state.language.language,
    translations: state.translation.translations,
})

const mapDispatchToProps = {
    addButtonSpinner,
    removeButtonSpinner,
    addPageSpinner,
    removePageSpinner
}
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ResetPassword);
