import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { DEFAULT_LANGUAGE_KEY } from "../../Constants/mainKeys";

function RedirectComponent(props) {
	const { language } = useSelector(state => state.language);
	console.log("test");
	useEffect(() => {
		props.history.push(`${language ? language : DEFAULT_LANGUAGE_KEY}`);
	}, []);
	return null;
}

export default withRouter(RedirectComponent);
