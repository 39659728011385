import React, { Component } from 'react';
import HeartAddedSvg from "../../Components/Svg/heartAdded";
import TranslationService from '../../Services/translationService';
import ApiService from "../../Services/apiService";
import { connect } from 'react-redux';
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import uuid from 'react-uuid';
import AlertService from '../../Services/alertService';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { COURSES_KEY } from '../../Constants/urlKeys';
import { changeShoppingCartOrWishListCount, changeWishListCount } from "../../Store/Actions/wishListOrShoppingCart";
import { ERROR_KEY, NUMBER_KEY, SHOPPING_CART_COUNT_KEY, SUCCESS_KEY, WISH_LIST_COUNT_KEY, WISH_LIST_PRODUCTS_IDS_KEYS } from '../../Constants/mainKeys';
import Parser from 'html-react-parser';
import NoDataImage from '../../assets/images/illustrations/nodata.svg';
class WishList extends Component {
  _isMounted = false;

  state = {
    courses: [],
    translationService: null,
    wishListCourses: [],
    isDisabledFields: false,
  }

  componentDidMount() {
    this._isMounted = true;
    this.setTranslations();
    this.getWishListCourses();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getWishListCourses = () => {
    const { user } = this.props;
    const spinnerId = uuid();
    var wishListProductsByIds = null;
    if (localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS)) {
      wishListProductsByIds = JSON.parse(localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS));
    }
    if (user) {
      this.props.addPageSpinner(spinnerId);
      ApiService.getWishListCourses().then(response => {
        if (response.data) {
          const wishListCourses = [...response.data];
          this._isMounted && this.setState({ wishListCourses });
        }
        this.props.removePageSpinner(spinnerId);
      }).catch(error => this.getFail(error, spinnerId));
    }
    if (!user && wishListProductsByIds) {
      this.props.addPageSpinner(spinnerId);
      const data = {
        wishListCourseIds: wishListProductsByIds
      }
      ApiService.getWishListCoursesForUnauthUsers(data).then(response => {
        if (response.data) {
          const wishListCourses = [...response.data];
          this._isMounted && this.setState({ wishListCourses });
        }
        this.props.removePageSpinner(spinnerId);
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  removeCourseFromWishList = (event, course) => {
    event.stopPropagation();
    const { user } = this.props;
    var { wishListCourses, translationService } = this.state;
    var wishListProductsByIds = null;
    if (localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS)) {
      wishListProductsByIds = JSON.parse(localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS))
    }
    if (user) {
      this.setState({ isDisabledFields: true });
      ApiService.removeCourseFromWishList(course.id).then(response => {
        if (response) {
          const result = wishListCourses.filter(courseItem => courseItem.id !== course.id);
          this.setState({ wishListCourses: result });
          if (response.data && (typeof response.data?.wishListCount === NUMBER_KEY && typeof response.data?.shoppingCartCount === NUMBER_KEY)) {
            localStorage.setItem(WISH_LIST_COUNT_KEY, response.data.wishListCount);
            localStorage.setItem(SHOPPING_CART_COUNT_KEY, response.data.shoppingCartCount);
            wishListProductsByIds = wishListProductsByIds && wishListProductsByIds.filter(productId => productId !== course.id);
            localStorage.setItem(WISH_LIST_PRODUCTS_IDS_KEYS, JSON.stringify(wishListProductsByIds));
            this.props.changeShoppingCartOrWishListCount(response.data.wishListCount, response.data.shoppingCartCount);
            AlertService.alert(SUCCESS_KEY, `${course.name} ${translationService.translate("TR_REMOVE_WISH_LIST_MSG")}`)
          }
        }
        this.setState({ isDisabledFields: false });
      }).catch(error => { this.getFail(error) });
    }
    if (!user && wishListProductsByIds) {
      wishListProductsByIds = wishListProductsByIds.filter(productId => productId !== course.id);
      localStorage.setItem(WISH_LIST_PRODUCTS_IDS_KEYS, JSON.stringify(wishListProductsByIds));
      this.props.changeWishListCount(wishListProductsByIds.length);
      wishListCourses = wishListCourses.filter(courseItem => courseItem.id !== course.id);
      this.setState({ wishListCourses });
    }
  }

  redirectToCouresView = (courseId) => {
    this.props.history.push(`/${this.props.language}/${COURSES_KEY}/${courseId}`)
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
    this.setState({ isDisabledFields: false })
  }

  render() {
    const { translationService, wishListCourses, isDisabledFields } = this.state;
    return (
      translationService ?
        <section className="m-section">
          <div className="container">
            <h1 className="section-title">{`${translationService.translate('TR_WISH_LIST')}`}</h1>
            <hr />
            <div className="course-slider-wrapper">
              <div className="course-slider d-block">
                <div className="row">
                  {
                    (wishListCourses && wishListCourses.length) ? wishListCourses.map(course => {
                      const ratingPercent = Math.round((course.rating * 100) / 5);
                      return <div key={course.id} className="col-lg-3 col-md-3 col-sm-4 col-6 mt-3">
                        <div className="course-wrapper course-wrapper-vertical" onClick={() => this.redirectToCouresView(course.id)}>
                          <div className="wish-list-image-wrapper">
                            <div className="d-flex align-items-center course-ruls-wrapper">
                              <div
                                className={`wish-list-icon ${isDisabledFields ? "disabled" : ""}`}
                                onClick={(event) => this.removeCourseFromWishList(event, course)}
                              >
                                <HeartAddedSvg />
                              </div>
                              {
                                course.isBestSeller ?
                                  <span className="course-status course-bestseller">{translationService.translate("TR_BESTSELLER")}</span>
                                  : null
                              }
                              {
                                course.isNew ?
                                  <span className="course-status course-new">{translationService.translate("TR_NEW")}</span>
                                  : null
                              }
                              {/* <span className="course-status course-new">New</span> */}
                            </div>
                            <div className="wish-list-image" style={{ backgroundImage: `url(${course.imagePath})` }} />
                          </div>
                          <div className="course-body-wrapper">
                            <h6 className="course-name">{course.name}</h6>
                            {/* <p className="course-description">{course.description}</p> */}
                            {
                              course.description ?
                                <span className="course-description max-height-50"><span className='course-description-block'>{Parser(course.description)}</span></span>
                                : null
                            }
                            <div className="course-review-wrapper d-flex align-items-center">
                              <span className="course-review-number text-orange mr-1">{course.rating}</span>
                              <div className="rating"><div style={{ width: `${ratingPercent}%` }}></div></div>
                              <span className="course-review-count"><span>{`(${course.approvedTotalReviews})`}</span></span>
                            </div>
                            <div className="course-price-wrapper d-flex">
                              <p className="course-new-price">{course.discount === 100 || !course.cost ? `${translationService.translate("TR_FREE")}` : course.cost}</p>
                              <p className="course-old-price">{course.oldCost !== 0 ? course.oldCost : null}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    }) :
                      <div className="no-data-container">
                        <div className="no-data-wrapper">
                          <p>{translationService.translate("TR_EMPTY_WISH_LIST")}</p>
                          <img src={NoDataImage} alt="/" />
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
        : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  language: state.language.language,
  user: state.user.user
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner,
  changeShoppingCartOrWishListCount,
  changeWishListCount
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(WishList);