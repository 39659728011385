import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import uuid from 'react-uuid';
import * as moment from "moment";
import ApiService from '../../Services/apiService';
import AlertService from '../../Services/alertService';
import TranslationService from '../../Services/translationService';
import { addPartialViewSpinner, removePartialViewSpinner, addModalSpinner, removeModalSpinner } from "../../Store/Actions/spinner";
import ModalComponent from '../../Components/ModalComponent/modalComponent';
import Input from '../../Components/Inputs/input';
import MainService from '../../Services/mainService';
import NoDataImage from "../../assets/images/illustrations/nodata.svg"
import { ERROR_KEY, NUMBER_KEY, SUCCESS_KEY } from '../../Constants/mainKeys';
import { COURSES_KEY, COURSE_KEY, MINDALAY_ADMIN, VERIFIED_KEY, UNVERIFIED_KEY, DISCOUNT_KEY } from '../../Constants/urlKeys';
import { setBackUrl } from "../../Store/Actions/main";
import ReactPaginate from 'react-paginate';
import Parser from 'html-react-parser';

class VerifiedCourses extends Component {

  state = {
    form: {
      discount: '',
      discountStartDate: '',
      discountEndDate: '',
    },
    translationService: null,
    courses: [],
    isShowDiscountModal: false,
    courseData: null,
    failedFields: [],
    isInvalidSubmit: false,
    isInvalidStartDate: false,
    isInvalidEndDate: false,
    pagination: null,
    activePageNumber: 0,
    pageSize: 10,
  }

  componentDidMount() {
    this.setTranslations();
    this.getCourses(1);
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ activePageNumber: 0 }, () => this.getCourses(1));
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getCourses = (currentPage) => {
    if (this.props.location.pathname.includes(`/${COURSES_KEY}/${VERIFIED_KEY}`)) {
      this.setState({ courses: [], pagination: null }, () => {
        this.getVerifiedCourses(currentPage);
      })
    }
    if (this.props.location.pathname.includes(`/${COURSES_KEY}/${UNVERIFIED_KEY}`)) {
      this.setState({ courses: [], pagination: null }, () => {
        this.getUnverifiedCourses(currentPage);
      })
    }
    if (this.props.location.pathname.includes(`/${COURSES_KEY}/${DISCOUNT_KEY}`)) {
      this.setState({ courses: [], pagination: null }, () => {
        this.getCoursesForDiscount(currentPage);
      })
    }
  }

  handlePageClick = (event) => {
    this.getCourses(event.selected + 1);
    this.setState({ activePageNumber: event.selected });
  }

  getUnverifiedCourses = (currentPage) => {
    const spinnerId = uuid();
    const { pageSize } = this.state;
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getUnverifiedCourses(currentPage, pageSize).then(response => {
      this.props.removePartialViewSpinner(spinnerId);
      if (response && response.data && response.pagination) {
        this.setState({ courses: response.data, pagination: response.pagination ? JSON.parse(response.pagination) : null })
      }
    }).catch(error => this.getFail(error, spinnerId))
  }

  getVerifiedCourses = (currentPage) => {
    const spinnerId = uuid();
    const { pageSize } = this.state;
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getVerifiedCourses(currentPage, pageSize).then(response => {
      if (response && response.data && response.pagination) {
        this.setState({ courses: response.data, pagination: response.pagination ? JSON.parse(response.pagination) : null })
      }
      this.props.removePartialViewSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId))
  }

  getCoursesForDiscount = (currentPage) => {
    const spinnerId = uuid();
    const { pageSize } = this.state;
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getCoursesForDiscount(currentPage, pageSize).then(response => {
      this.props.removePartialViewSpinner(spinnerId);
      if (response && response.data && response.pagination) {
        this.setState({ courses: response.data, pagination: response.pagination ? JSON.parse(response.pagination) : null });
      }
    }).catch(error => this.getFail(error, spinnerId))
  }

  redirectToCourseViewPage = (course) => {
    if (this.props.location.pathname.includes(VERIFIED_KEY) || this.props.location.pathname.includes(UNVERIFIED_KEY)) {
      this.props.setBackUrl(this.props.location.pathname);
      this.props.history.push(`/${this.props.language}/${MINDALAY_ADMIN}/${COURSE_KEY}/${course.id}`);
    }
    if (this.props.location.pathname.includes(DISCOUNT_KEY)) {
      var discount = course.discount ? course.discount : "";
      var discountStartDate = course.discountStartDate ? moment(MainService.convertUTCDateToLocalDate(new Date(course.discountStartDate))).format("YYYY-MM-DDTHH:mm:ss") : "";
      var discountEndDate = course.discountEndDate ? moment(MainService.convertUTCDateToLocalDate(new Date(course.discountEndDate))).format("YYYY-MM-DDTHH:mm:ss") : "";
      this.setState(prevState => ({
        ...prevState,
        isShowDiscountModal: true,
        courseData: course,
        form: {
          ...prevState.form,
          discount,
          discountStartDate,
          discountEndDate,
        }
      }));
    }
  }

  cancel = () => {
    this.setState(prevState => ({
      ...prevState,
      isShowDiscountModal: false,
      isInvalidSubmit: false,
      isInvalidStartDate: false,
      isInvalidEndDate: false,
      form: {
        ...prevState.form,
        discount: '',
        discountStartDate: '',
        discountEndDate: '',
      }
    }));
  }

  convertStrToArray = (str) => {
    const array = [];
    str = JSON.parse(str);
    const keys = Object.keys(str);
    const values = Object.values(str);
    keys.forEach((key, index) => {
      array.push({
        [key]: values[index]
      });
    });
    return [...array];
  }

  onNumberChange = event => {
    const failedFields = this.removeFailedFields(event.target.name);
    if (+event.target.value.charAt(0) === 0) {
      event.target.value = event.target.value.substring(1);
    }
    if (event.target.value.includes("e")) { return false };
    if (typeof +event.target.value === NUMBER_KEY && event.target.value <= 100) {
      this.setState(prevState => ({ ...prevState, failedFields, form: { ...prevState.form, [event.target.name]: +event.target.value } }));
    }
  };

  onDateChange = event => {
    const failedFields = this.removeFailedFields(event.target.name);
    this.setState(prevState => ({
      ...prevState,
      failedFields,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value
      }
    }));
  };

  checkDateFormatAndIsValid = (date, name, error) => {
    if (!date || !name) { return; }
    const { discountStartDate, discountEndDate } = this.state.form;
    const { isInvalidStartDate, isInvalidEndDate } = this.state;
    const isValidDateFormat = MainService.isValidDateTime(date);
    const isValidDate = MainService.validateDate(date);
    if (!isValidDateFormat || !isValidDate) {
      this.setState({ [error]: true });
    } else this.setState({ [error]: false });

    if (discountStartDate && discountEndDate && !isInvalidStartDate && !isInvalidEndDate) {
      const dateCompaire = MainService.checkDates(discountStartDate, discountEndDate);
      if (dateCompaire && isValidDate && isValidDateFormat) {
        this.setState({ [error]: false });
      } else {
        this.setState({ [error]: true });
      }
    }
  }

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  }

  convertKeywordsToString = (string) => {
    const objects = JSON.parse(string);
    return objects && Object.values(objects).join(' ');
  }

  onSubmit = () => {
    const { courseData } = this.state;
    const { isInvalidStartDate, isInvalidEndDate, translationService } = this.state;
    const spinnerId = uuid();
    var form = { ...this.state.form };
    if (
      isInvalidStartDate ||
      isInvalidEndDate ||
      !form.discount ||
      !form.discountStartDate ||
      !form.discountEndDate
    ) {
      this.setState({ isInvalidSubmit: true });
    } else {
      form.id = courseData.id;
      form.discountEndDate = form.discountEndDate ? moment.utc(new Date(form.discountEndDate)).format("YYYY-MM-DDTHH:mm:ss") : "";
      form.discountStartDate = form.discountStartDate ? moment.utc(new Date(form.discountStartDate)).format("YYYY-MM-DDTHH:mm:ss") : "";
      this.props.addModalSpinner(spinnerId);
      ApiService.courseDiscount(form).then(() => {
        this.props.removeModalSpinner(spinnerId);
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
        this.cancel();
        this.getCourses(this.state.activePageNumber ? this.state.activePageNumber + 1 : 1);
      }).catch(error => this.submitFail(spinnerId, error));
    }
  }

  getFail = (error, spinnerId) => {
    spinnerId && this.props.removePartialViewSpinner(spinnerId);
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  submitFail = (spinnerId, errors) => {
    this.props.removeModalSpinner(spinnerId);
    const failedFields = errors;
    this.setState({ failedFields });
    errors && AlertService.alert((AlertService.checkMessageType(errors.respcode) || ERROR_KEY), errors);
  }

  render() {
    const { languages } = this.props
    const { discount, discountStartDate, discountEndDate } = this.state.form
    const {
      translationService,
      courses,
      courseData,
      isShowDiscountModal,
      isInvalidSubmit,
      failedFields,
      isInvalidStartDate,
      isInvalidEndDate,
      pagination,
      activePageNumber,
    } = this.state;

    const isVerifiedCoursesPage = this.props.location.pathname.includes(`/${COURSES_KEY}/${VERIFIED_KEY}`) ? true : false;
    const isUnerifiedCoursesPage = this.props.location.pathname.includes(`/${COURSES_KEY}/${UNVERIFIED_KEY}`) ? true : false;
    const isDiscountCoursesPage = this.props.location.pathname.includes(`/${COURSES_KEY}/${DISCOUNT_KEY}`) ? true : false;

    return (
      translationService ? <div className="container">
        <div className="row">
          <div className="col-12">
            {
              isVerifiedCoursesPage ? <h2 className="content-title p-0">{translationService.translate("TR_VERIFIED_COURSES")}</h2> :
                isUnerifiedCoursesPage ? <h2 className="content-title p-0">{translationService.translate("TR_UNVERIFIED_COURSES")}</h2> :
                  isDiscountCoursesPage ? <h2 className="content-title p-0">{translationService.translate("TR_COURSES_FOR_DISCOUNT")}</h2> : null
            }
            <hr />
          </div>
        </div>
        {
          isShowDiscountModal && courseData ?
            <ModalComponent
              title={courseData.name}
              actionButtonTitle={translationService.translate("TR_DISCOUNT")}
              isLarge={true}
              cancel={this.cancel}
              onSubmit={this.onSubmit}
            >
              <div className="course-discount-modal-body">
                <div className="mt-1">
                  {
                    courseData.creatorUserName ?
                      <span className="d-block my-1"><b>{`${translationService.translate("TR_CREATOR")}: `}</b><span>{courseData.creatorUserName}</span></span>
                      : null
                  }
                  {
                    (courseData.languageId || typeof courseData.languageId === NUMBER_KEY) && languages ?
                      <span className="d-block my-1">
                        <b>{`${translationService.translate("TR_LANGUAGE")}: `}</b>
                        {
                          languages.map((lang, index) => {
                            if (lang.languageId === courseData.languageId) {
                              return <span key={index}>{lang.name}</span>
                            } else { return false; }
                          })
                        }
                      </span>
                      : null
                  }
                  {
                    courseData.cost ?
                      <span className="d-block my-1"><b>{`${translationService.translate("TR_PRICE")}: `}</b><span>{courseData.cost}</span></span>
                      : null
                  }
                  {
                    courseData.discount || typeof courseData.discount === NUMBER_KEY ?
                      <span className="d-block my-1"><b>{`${translationService.translate("TR_DISCOUNT_PERCENTAGE")}: `}</b><span>{courseData.discount}</span></span>
                      : null
                  }
                  {
                    courseData.discountStartDate ?
                      <span className="d-block my-1"><b>{`${translationService.translate("TR_DISCOUNT_START_DATE")}: `}</b>
                        <span>{moment(MainService.convertUTCDateToLocalDate(new Date(courseData.discountStartDate))).format("lll")}</span>
                      </span>
                      : null
                  }
                  {
                    courseData.discountEndDate ?
                      <span className="d-block my-1"><b>{`${translationService.translate("TR_DISCOUNT_END_DATE")}: `}</b>
                        <span>{moment(MainService.convertUTCDateToLocalDate(new Date(courseData.discountEndDate))).format("lll")}</span>
                      </span>
                      : null
                  }
                  {
                    courseData.period ?
                      <span className="d-block my-1"><b>{`${translationService.translate("TR_DURATION_DAYS")}: `}</b><span>{courseData.period}</span></span>
                      : null
                  }
                  {
                    courseData.categories ?
                      <span className="d-block my-1"><b>{`${translationService.translate("TR_COURSE_CATEGORIES")}: `}</b>
                        {courseData.categories && courseData.categories.length ?
                          <span>
                            {courseData.categories.map((category, index) => {
                              if (index + 1 === courseData.categories.length) {
                                return <span key={index}>{category.name}</span>
                              } else {
                                return <span key={index}>{category.name},</span>
                              }
                            })}
                          </span>
                          : null}
                      </span>
                      : null
                  }
                  {
                    courseData.keywords ?
                      <span className="d-block my-1"><b>{`${translationService.translate("TR_COURSE_KEY_WORDS")}: `}</b><span>{this.convertKeywordsToString(courseData.keywords)}</span></span>
                      : null
                  }
                  {
                    courseData.creatorUserName ?
                      <span className="d-block my-1"><b>{`${translationService.translate("TR_EXPERIENCE_LEVEL")}: `}</b><span>{courseData.creatorUserName}</span></span>
                      : null
                  }
                  {
                    courseData.whatYouWillLearn ?
                      <span className="d-block my-1">
                        <b>{`${translationService.translate("TR_WHAT_YOU_WILL_LEARN_DESC")}: `}</b>
                        <span>
                          {this.convertStrToArray(courseData.whatYouWillLearn).map((desc, index) => {
                            return <span key={index}>
                              {Object.values(desc)}
                            </span>
                          })}
                        </span>
                      </span>
                      : null
                  }
                  {
                    courseData.requirements ?
                      <span className="d-block"><b>{`${translationService.translate("TR_REQUIREMENTS")}: `}</b>
                        <span>
                          {this.convertStrToArray(courseData.requirements).map((desc, index) => {
                            return <span key={index}>
                              {Object.values(desc)}
                            </span>
                          })}
                        </span>
                      </span>
                      : null
                  }
                  {
                    courseData.targetStudents ?
                      <span className="d-block"><b>{`${translationService.translate("TR_TARGETED_STUDENTS")}: `}</b>
                        <span>
                          {this.convertStrToArray(courseData.targetStudents).map((desc, index) => {
                            return <span key={index}>
                              {Object.values(desc)}
                            </span>
                          })}
                        </span>
                      </span>
                      : null
                  }
                  {
                    courseData.welcomeMessage ?
                      <span className="d-block my-1"><b>{`${translationService.translate("TR_WELCOME_MESSAGE")}: `}</b><span>{courseData.welcomeMessage}</span></span>
                      : null
                  }
                  {
                    courseData.congratulationMessage ?
                      <span className="d-block my-1"><b>{`${translationService.translate("TR_CONGRETULATION_MESSAGE")}: `}</b><span>{courseData.congratulationMessage}</span></span>
                      : null
                  }
                </div>
                <hr />

                <div className="">
                  <div className="col-lg-6 col-12">
                    <Input
                      type="number"
                      id="discount"
                      name="discount"
                      min="0"
                      maxLength="100"
                      value={discount}
                      isInvalidSubmit={isInvalidSubmit}
                      labelValue={`${translationService.translate("TR_DISCOUNT_PERCENTAGE")} *`}
                      onChange={this.onNumberChange}
                      failedFields={failedFields}
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <Input
                      type="datetime-local"
                      id="discountStartDate"
                      name="discountStartDate"
                      min="0"
                      value={discountStartDate}
                      isInvalidSubmit={isInvalidSubmit}
                      inputClassName={isInvalidStartDate ? "is-invalid error-bordered" : ""}
                      labelValue={`${translationService.translate("TR_DISCOUNT_START_DATE")} *`}
                      onChange={this.onDateChange}
                      onBlur={() => this.checkDateFormatAndIsValid(discountStartDate, "discountStartDate", "isInvalidStartDate")}
                      failedFields={failedFields}
                    />
                    {
                      isInvalidStartDate ? <small className="red-color">{translationService.translate("TR_INVALID_DATES")}</small> : null
                    }
                  </div>
                  <div className="col-lg-6 col-12">
                    <Input
                      type="datetime-local"
                      id="discountEndDate"
                      name="discountEndDate"
                      min="0"
                      value={discountEndDate}
                      isInvalidSubmit={isInvalidSubmit}
                      inputClassName={isInvalidEndDate ? "is-invalid error-bordered" : ""}
                      labelValue={`${translationService.translate("TR_DISCOUNT_END_DATE")} *`}
                      onChange={this.onDateChange}
                      onBlur={() => this.checkDateFormatAndIsValid(discountEndDate, "discountEndDate", "isInvalidEndDate")}
                      failedFields={failedFields}
                    />
                    {
                      isInvalidEndDate ? <small className="red-color">{translationService.translate("TR_INVALID_DATES")}</small> : null
                    }
                  </div>
                </div>
              </div>
            </ModalComponent>
            : null
        }
        {
          courses && courses.length && pagination ?
            <div className="row">
              <div className="col-12">
                <div className="mindayal-admin-table table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{translationService.translate("TR_CREATOR")}</th>
                        <th>{translationService.translate("TR_NAME")}</th>
                        <th>{translationService.translate("TR_DESCRIPTION")}</th>
                        <th>{translationService.translate("TR_COST")}</th>
                        <th>{translationService.translate("TR_DURATION_DAYS")}</th>
                        <th>{translationService.translate("TR_DISCOUNT_PERCENTAGE")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        courses.map((course, index) => {
                          return <tr key={index} onClick={() => this.redirectToCourseViewPage(course)}>
                            <td><div className="mindalay-admin-table-td-item">{course.creatorUserName}</div></td>
                            <td><div className="mindalay-admin-table-td-item">{course.name}</div></td>
                            <td><div className="mindalay-admin-table-td-item">{course.description ? <span className='course-description-block'>{Parser(course.description)}</span> : ""}</div></td>
                            <td><div className="mindalay-admin-table-td-item">{course.cost}</div></td>
                            <td><div className="mindalay-admin-table-td-item">{course.period}</div></td>
                            <td><div className="mindalay-admin-table-td-item">{course.discount}</div></td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            : <div className="no-data-container">
              <div className="no-data-wrapper">
                <p>{translationService.translate("TR_NO_DATA")}</p>
                <img src={NoDataImage} alt="/" />
              </div>
            </div>
        }
        {
          pagination ?
            <div className="row mt-4">
              <div className="col-12">
                <div>
                  <ReactPaginate
                    nextLabel={translationService.translate("TR_NEXT")}
                    onPageChange={this.handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pagination.TotalPages}
                    previousLabel={translationService.translate("TR_PREVIOUS")}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                    forcePage={activePageNumber}
                  />
                </div>
              </div>
            </div>
            : null
        }
      </div> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  language: state.language.language,
  user: state.user.user
})

const mapDispatchToProps = {
  addPartialViewSpinner,
  removePartialViewSpinner,
  addModalSpinner,
  removeModalSpinner,
  setBackUrl
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(VerifiedCourses);

