import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import { Card } from "react-bootstrap";
import FragmenetsModal from "../Components/Modals/FragmenetsModal";
import { Link } from "react-router-dom";
import ArrowBackSvg from "./../../../Components/Svg/arrowBackSvg";
import GamifyApiService from "./../../../Services/gamifyApiService";
import AlertService from "../../../Services/alertService";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import uuid from "react-uuid";
import { ADMIN_USER_TYPE, ERROR_KEY } from "../../../Constants/mainKeys";
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
import ActionButton from "./../../../Components/ActionButton/actionButton";
import { GAMIFICATION_URL_KEY } from "../../../Constants/apiKeys";
import ResourceBlock from "./Components/ResourceBlock";

const buttonSpinnerId = uuid();
const Resources = props => {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [resources, setResources] = useState([]);
	const [selectedResource, setSelectedResource] = useState(null);
	const [isDisabledFields, setIsDisabledFields] = useState(false);

	useEffect(() => {
		getResources();
		return () => {
			setResources([]);
		};
	}, []);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const generateGamifyToken = scopeType => {
		if (!scopeType) {
			return false;
		}
		setIsDisabledFields(true);
		dispatch(addButtonSpinner(buttonSpinnerId));
		GamifyApiService.generateGamifyToken({ scopeType })
			.then(response => {
				if (response && response.data) {
					let redirectUrl = "";
					if (resources && resources.length) {
						redirectUrl = `${GAMIFICATION_URL_KEY}/resources/${response.data}`;
					} else {
						redirectUrl = `${GAMIFICATION_URL_KEY}/resources/${response.data}/form?isDefault=true`;
					}
					window.open(redirectUrl, "_blank");
				}
				dispatch(removeButtonSpinner(buttonSpinnerId));
				setIsDisabledFields(false);
			})
			.catch(error => getFail(error, buttonSpinnerId));
	};

	const getResources = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		const data = {
			resourceImageSize: 2,
			fragmentImageSize: 1,
		};
		GamifyApiService.getResources(data)
			.then(response => {
				if (response && response.data) {
					setResources(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
		setIsDisabledFields(false);
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<div className="d-flex align-items-center">
						<h2 className="content-title p-0">
							<Link
								to={`/${language}/gamification/dashboard`}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							{translationService.translate("TR_RESOURCES")}
						</h2>
					</div>
					<PageInfoBlock
						pageTitle="admin resources"
						pageName={
							user && user.userTypeId === ADMIN_USER_TYPE
								? "admin_resources"
								: "student_resources"
						}
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-self-stretch">
				<div className="col-12">
					<div className="d-flex justify-content-end align-items-center flex-wrap mb-3">
						<button
							type="button"
							className={`mindalay--btn-primary-outline mindalay--btn-small my-sm-0 my-2 ${
								isDisabledFields ? "disabled" : ""
							}`}
							onClick={() => generateGamifyToken(1)}>
							+{translationService.translate("TR_ADD_RESOURCE")}
						</button>
					</div>
				</div>
				{resources && resources.length ? (
					resources.map((item, index) => {
						return (
							<div key={index} className="col-12 col-md-6 col-lg-3 p-2">
								<ResourceBlock
									item={item}
									setSelectedResource={setSelectedResource}
									translationService={translationService}
								/>
							</div>
						);
					})
				) : (
					<div className="col-12">
						<div className="row justify-content-center">
							<div className="col-12 col-md-8 col-lg-6">
								<div
									className="gamification-nodata"
									onClick={() => generateGamifyToken(1)}>
									<span>
										<i className={`fas fa-plus`}></i>
									</span>
									<p>
										{translationService.translate(
											"TR_CREATE_YOUR_FIRST_RESOURCE",
										)}
									</p>
								</div>
							</div>
						</div>
					</div>
				)}
				{selectedResource ? (
					<FragmenetsModal
						selectedResource={selectedResource}
						setSelectedResource={setSelectedResource}
						translationService={translationService}
					/>
				) : null}
			</div>
		</div>
	) : null;
};

export default withRouter(Resources);
