import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import HeartSvg from '../../Components/Svg/heartSvg';
import HeartAddedSvg from "../../Components/Svg/heartAdded";
import ApiService from '../../Services/apiService';
import AlertService from '../../Services/alertService';
import TranslationService from '../../Services/translationService';
import { changeShoppingCartOrWishListCount, changeWishListCount } from "../../Store/Actions/wishListOrShoppingCart";
import { COURSES_KEY } from "../../Constants/urlKeys";
import { ADDED_KEY, ERROR_KEY, NUMBER_KEY, REMOVED_KEY, SHOPPING_CART_COUNT_KEY, SUCCESS_KEY, WISH_LIST_COUNT_KEY } from '../../Constants/mainKeys';
import { withRouter } from 'react-router';
import ChangeStorageDataService from '../../Services/changeStorageDataService';
import Parser from 'html-react-parser';
import TextSpinner from "../../Components/Spinners/textSpinner";
import { addTextSpinner, removeTextSpinner } from './../../Store/Actions/spinner';

const CourseBlock = props => {
  const dispatch = useDispatch();

  const [translationService, setTranslationService] = useState(null);
  const translations = useSelector(state => state.translation.translations);
  const hasUser = useSelector(state => state.user.hasUser);
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);
  const course = props.course;
  const textSpinners = useSelector(state => state.spinner.textSpinners);
  const [currentCourseId, setCurrentCourseId] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const redirectToCourseView = (event) => {
    event.stopPropagation();
    props.history.push(`/${language}/${COURSES_KEY}/${course.id}`)
  };

  const addCourseToWishList = (event, course) => {
    event.stopPropagation();
    course && setCurrentCourseId(course.id);
    const spinnerId = `course-${course.id}`;
    dispatch(addTextSpinner(spinnerId));
    if (hasUser) {
      if (!course.isInWishlist) {
        ApiService.addCourseToWishListOrCart(props.course.id, false).then(response => {
          response && AlertService.alert(SUCCESS_KEY, `${props.course.name} ${translationService.translate('TR_ADDED_WISH_LIST_MSG')}`);
          props.updateCourses && props.updateCourses(course.id);
          props.updateCoursesData && props.updateCoursesData("courses", course.id, course);
          if (response.data && (typeof response.data?.wishListCount === NUMBER_KEY && typeof response.data?.shoppingCartCount === NUMBER_KEY)) {
            localStorage.setItem(WISH_LIST_COUNT_KEY, response.data.wishListCount);
            localStorage.setItem(SHOPPING_CART_COUNT_KEY, response.data.shoppingCartCount);
            dispatch(changeShoppingCartOrWishListCount(response.data.wishListCount, response.data.shoppingCartCount));
            dispatch(removeTextSpinner(spinnerId));
          }
        }).catch(error => getFail(error, spinnerId));
      } else if (course.isInWishlist) {
        ApiService.removeCourseFromWishList(props.course.id).then(response => {
          response && AlertService.alert(SUCCESS_KEY, `${props.course.name} ${translationService.translate('TR_REMOVE_WISH_LIST_MSG')}`);
          props.updateCourses && props.updateCourses(course.id);
          props.updateCoursesData && props.updateCoursesData("courses", course.id, course);
          if (response.data && (typeof response.data?.wishListCount === NUMBER_KEY && typeof response.data?.shoppingCartCount === NUMBER_KEY)) {
            localStorage.setItem(WISH_LIST_COUNT_KEY, response.data.wishListCount);
            localStorage.setItem(SHOPPING_CART_COUNT_KEY, response.data.shoppingCartCount);
            dispatch(changeShoppingCartOrWishListCount(response.data.wishListCount, response.data.shoppingCartCount));
            dispatch(removeTextSpinner(spinnerId));
          }
        }).catch(error => getFail(error, spinnerId));
      }
    } else {
      event.stopPropagation();
      ChangeStorageDataService.addOrRemoveWishListProduct(course).then(response => {
        if (response) {
          if (response.status === ADDED_KEY) {
            AlertService.alert(SUCCESS_KEY, `${response.course.name} ${translationService.translate('TR_ADDED_WISH_LIST_MSG')}`);
            localStorage.setItem(WISH_LIST_COUNT_KEY, response.wishListCount);
            props.updateCourses && props.updateCourses(course.id);
            props.updateCoursesData && props.updateCoursesData("courses", course.id, course);
            dispatch(changeWishListCount(response.wishListCount));
            dispatch(removeTextSpinner(spinnerId));
          }
          if (response.status === REMOVED_KEY) {
            AlertService.alert(SUCCESS_KEY, `${response.course.name} ${translationService.translate('TR_REMOVE_WISH_LIST_MSG')}`);
            localStorage.setItem(WISH_LIST_COUNT_KEY, response.wishListCount);
            props.updateCourses && props.updateCourses(course.id);
            props.updateCoursesData && props.updateCoursesData("courses", course.id, course);
            dispatch(changeWishListCount(response.wishListCount));
            dispatch(removeTextSpinner(spinnerId));
          }
        }
      });
    }
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removeTextSpinner(spinnerId));
  };

  const ratingPercent = Math.round(course.rating * 100) / 5;
  const currentCourseWishListSpinner = textSpinners.find(spinner => spinner === `course-${currentCourseId}`);

  return (translationService ? <div className="course-wrapper course-wrapper-vertical" onClick={event => redirectToCourseView(event)}>
    <div className="course-image-wrapper">
      <div className="d-flex align-items-center course-ruls-wrapper">
        {
          user && user.isBelongToStudent
            ? null
            : <div className="wish-list-icon" onClick={(event) => {
              if (currentCourseWishListSpinner) {
                event.stopPropagation()
              } else {
                addCourseToWishList(event, props.course)
              }
            }}>
              {/* {
                (props.creatorId && user) && (props.creatorId !== user.id) && props.course.isInWishlist ? <HeartAddedSvg /> : null
              }
              {
                (props.creatorId && user) && (props.creatorId !== user.id) && !props.course.isInWishlist ? <HeartSvg /> : null
              }
              {
                (!props.creatorId && user) ? props.course.isInWishlist ? <HeartAddedSvg /> : <HeartSvg /> : null
              }
              {
                !user ? props.course.isInWishlist ? <HeartAddedSvg /> : <HeartSvg /> : null
              } */}
              {
                currentCourseWishListSpinner ?
                  <TextSpinner spinner={currentCourseWishListSpinner} color="#ffffff" className="ml-2" /> :
                  (props.creatorId && user) && (props.creatorId !== user.id) && props.course.isInWishlist ? <HeartAddedSvg /> :
                    (props.creatorId && user) && (props.creatorId !== user.id) && !props.course.isInWishlist ? <HeartSvg /> :
                      (!props.creatorId && user) ? props.course.isInWishlist ? <HeartAddedSvg /> : <HeartSvg /> :
                        !user ? props.course.isInWishlist ? <HeartAddedSvg /> : <HeartSvg /> :
                          null
              }
            </div>
        }
        {
          course.isBestSeller ?
            <span className="course-status course-bestseller">{translationService.translate("TR_BESTSELLER")}</span>
            : null
        }
        {
          course.isNew ?
            <span className="course-status course-new">{translationService.translate("TR_NEW")}</span>
            : null
        }
      </div>
      <div style={{ backgroundImage: `url(${course.imagePath})` }} className="course-image"></div>
    </div>
    <div className="course-body-wrapper filtered-courses overflow-hidden">
      <h6 className="course-name">{course.name}</h6>
      {
        course.description ?
          <span className="course-description max-height-50 max-line-1">
            <span className='course-description-block'>
              <span className='course-description-block'>{Parser(course.description)}</span>
            </span>
          </span>
          : null
      }
      <div className="course-review-wrapper d-flex align-items-center">
        <span className="course-review-number text-orange mr-1">{course.rating}</span>
        <div className="rating">
          <div style={{ width: `${ratingPercent}%` }}></div>
        </div>
        {
          course.approvedTotalReviews || course.approvedTotalReviews === 0
            ? <span className="course-review-count"><span>(</span>{course.approvedTotalReviews}<span>)</span></span> : null
        }
      </div>
      <div className="course-price-wrapper d-flex">
        <p className="course-new-price">
          {
            !course.cost || course.discount === 100 ? translationService.translate('TR_FREE') : course.cost
          }
        </p>
        {
          course.oldCost ? <p className="course-old-price ml-2">{course.oldCost}</p> : null
        }
      </div>
    </div>
  </div> : null
  );
}

export default withRouter(CourseBlock);


