import React, { useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import InfoSvg from './../Svg/infoSvg';
import { useSelector, useDispatch } from 'react-redux';
import TranslationService from './../../Services/translationService';
import { setQuestionMarkSpinner } from '../../Store/Actions/main';
import $ from "jquery";

export default function SmallQuestionMark(props) {

  const dispatch = useDispatch()
  const {translations} = useSelector(state => state.translation);
  const {currentQuestionMarkData} = useSelector(state => state.main);
  const [translationService, setTranslationService] = useState(null);
  const [questionTextBlockIsTop, setQuestionTextBlockIsTop] = useState(true);

  useEffect(() => {
    getElementTop(props.id);
    return () => {
      dispatch(setQuestionMarkSpinner(false));
    };
  }, [currentQuestionMarkData]);


  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const getElementTop = (id) => {
    if (currentQuestionMarkData && currentQuestionMarkData.name.trim() === props.id) {
      var w = $(window);
      let questionMarkOffset = $(`#span_${props.id}`).offset();
      if (questionMarkOffset?.top - w?.scrollTop() >= 0) {
        setQuestionTextBlockIsTop(true);
      } else {
        setQuestionTextBlockIsTop(false);
      }
    }
  }

  return translationService ? <div
    id={props.id}
    className={`info-title d-inline-block 
   ${props.blockClassName ? props.blockClassName : ""}`}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={() => setQuestionTextBlockIsTop(true)}
  >
    <div className="question-text-block">
      <InfoSvg />
      <span
        id={`span_${props.id}`}
        style={{ display: "none" }}
        className={questionTextBlockIsTop ? "" : "question-text-block-position-bottom"}
      >
        {currentQuestionMarkData && currentQuestionMarkData.name.trim() === props.id ?
          Parser(currentQuestionMarkData.description) :
          translationService.translate("TR_LOADING")}
      </span>
    </div>
  </div>
    : null
}
