import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import * as moment from "moment";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import InputRadio from "../../Components/Inputs/inputRadio";
import DataListOption from "../../Components/SelectOption/dataListOption";
import MainService from "../../Services/mainService";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import { addPageSpinner, removePageSpinner, addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";
import {
    ON_GOING_KEY,
    START_TIME_KEY,
    STATE_KEY,
    ATTEMPT_ID_KEY,
    GRACE_PERIOD_END_DATE_KEY,
} from "../../Constants/urlKeys";
import {
    ALPHABET_KEY,
    CHOISE_NUMBERING_TYPES_ARR_KEY,
    ERROR_KEY,
    TIME_TYPES_ARR_KEY,
    GRADING_METHODS_ARR_KEY,
} from "../../Constants/mainKeys";
import Textarea from "../../Components/Inputs/textArea";
import Parser from 'html-react-parser';
import CloseSvg from './../../Components/Svg/closeSvg';
import ReactPaginate from "react-paginate";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";


class QuizAttempt extends Component {

    searchParams = new URLSearchParams(this.props.location.search);
    stateParam = this.searchParams.get(STATE_KEY);
    choiseNumbering = CHOISE_NUMBERING_TYPES_ARR_KEY;
    timeLimitTypes = TIME_TYPES_ARR_KEY;
    alphabet = ALPHABET_KEY;
    gradeingMethods = GRADING_METHODS_ARR_KEY;
    interval;
    windowWidth = window.innerWidth;

    state = {
        currentQuestionIndex: 0,
        questions: [],
        questionsInfo: [],
        quizId: +this.props.match.params.quizId,
        studentId: +this.props.match.params.studentId,
        quizState: this.stateParam ? +this.stateParam : null,
        quizName: '',
        quizData: null,
        reviewAndSubmit: false,
        quizAttemptId: null,
        isAttemptFinished: this.stateParam ? +this.stateParam === 2 : false,
        canBeChecked: null,
        isInvalidSubmit: false,
        invalidQuestionId: null,
        spinnerId: uuid(),
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
        failedFields: [],
        checkedQuestionIds: [],
        translationService: null,
        answerMaxLength: 1000,
        zoomImagePath: null,
        activePageNumber: 0,
        form: {
            formQuestions: []
        }
    };

    componentDidMount() {
        this.setTranslations();
        const { quizState } = this.state;
        const onGoingId = this.searchParams.get(ON_GOING_KEY);
        const attemptId = this.searchParams.get(ATTEMPT_ID_KEY);

        if ((quizState === 1 || quizState === 2) && attemptId) {
            this.openQuizAttempt(attemptId, ApiService.getReviewQuizAttempt);
        }
        if (!onGoingId && !attemptId) {
            this.props.history.push(`/${this.props.language}`);
        }
        // window.addEventListener("beforeunload", this.onUnload);
        window.addEventListener('keydown', this.zoomOut);
    }

    componentDidUpdate() {
        this.setTranslations();
        // window.addEventListener("beforeunload", this.onUnload);

        var questionDropdownBlocks = document.querySelectorAll('.question-dropdown-block')
        if (questionDropdownBlocks && questionDropdownBlocks.length) {
            for (let i = 0; i < questionDropdownBlocks.length; i++) {
                questionDropdownBlocks[i].addEventListener("click", (event) => {
                    if (event.target.tagName.toLowerCase() === 'img') {
                        this.getImageSrc(event.target);
                    }
                }, { once: true });
            }
        }
    }

    componentWillUnmount() {
        // window.removeEventListener("beforeunload", this.onUnload);
        window.removeEventListener('keydown', this.zoomOut);
        clearInterval(this.interval);
        var questionDropdownBlocks = document.querySelectorAll('.question-dropdown-block')
        if (questionDropdownBlocks && questionDropdownBlocks.length) {
            for (let i = 0; i < questionDropdownBlocks.length; i++) {
                questionDropdownBlocks[i].removeEventListener("click", (event) => {
                    if (event.target.tagName.toLowerCase() === 'img') {
                        this.getImageSrc(event.target);
                    }
                });
            }
        }
    }

    getCheckedquestions = (attemptId) => {
        if (!attemptId) { return false; }
        ApiService.getCheckedquestions(+attemptId).then(response => {
            if (response && response.data) {
                this.setState({
                    checkedQuestionIds: response.data
                })
            }
        }).catch(error => this.getFail(error))
    };

    getImageSrc = (element) => {
        if (element && element.src) {
            this.setState({ zoomImagePath: element.src });
        }
    }

    zoomOut = (event) => {
        if (event.key === "Escape") {
            this.hideZoomImageVideo();
        }
    }

    hideZoomImageVideo = () => {
        this.setState({ zoomImagePath: null });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.translations
            && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
            this.setState({ translationService: new TranslationService(nextProps.translations) });
        }
        return true;
    }

    setTranslations = () => {
        if (!this.state.translationService && this.props.translations) {
            this.setState({ translationService: new TranslationService(this.props.translations) });
        }
    }

    openQuizAttempt = (quizAttemptId, methodRef) => {
        const spinnerId = uuid();
        this.props.addPageSpinner(spinnerId);
        methodRef(quizAttemptId).then(response => {
            if (response && response.data && response.data.state === 0) {
                this.getCheckedquestions(quizAttemptId);
            }
            this.props.removePageSpinner(spinnerId);
            this.getQuizById(this.state.quizId, this.state.studentId, response.data);
        }).catch(e => this.getFail(spinnerId, e));
    }

    getQuizById = (quizId, studentId, attemptData) => {
        const spinnerId = uuid();
        this.props.addPageSpinner(spinnerId);
        ApiService.getLectureQuiz(quizId, studentId).then(response => {
            const questions = response.data.questions;
            let questionsInfo = attemptData.questionsInfo;
            questionsInfo = questionsInfo && questionsInfo.filter(questionInfo => questions.findIndex(data => data.id === questionInfo.questionId) !== -1 ? questionInfo : null);
            let formQuestions = [];
            const checkedQuestionIds = [...this.state.checkedQuestionIds];
            if (questions && questions.length) {
                formQuestions = this.addQuestionsFormFields(questions, attemptData.id, questionsInfo);
            }
            questions.forEach(question => {
                if (question.questionType === 1) {
                    question.matchingQuestionAnswers = question.questionAnswers.filter(data => data.question);
                }
            });
            questionsInfo.forEach(questionInfo => {
                const selectedQuestion = questions && questions.find(data => data.id === questionInfo.questionId);
                if (questionInfo.isShuffle) {
                    const questionAnswers = this.shuffle([...selectedQuestion.questionAnswers]);
                    selectedQuestion.questionAnswers = [...questionAnswers];
                }
                // if (questionInfo.tryAgain) {
                //   checkedQuestionIds.push(questionInfo.questionId);
                // }
                if (questionInfo.questionType === 0 || questionInfo.questionType === 2) {
                    // set radio buttons value && short answer value  
                    const questionIndex = questions.findIndex(data => data.id === questionInfo.questionId);
                    if (questionIndex !== -1) {
                        const { attemptAnswers } = formQuestions[questionIndex];
                        const selectedFormQuestion = { ...formQuestions[questionIndex] };
                        const currentAnswer = attemptAnswers && attemptAnswers.length && attemptAnswers.find(answer => answer.questionId === selectedFormQuestion.questionId);
                        if (currentAnswer) {
                            selectedFormQuestion.answerId = currentAnswer.answerId ? currentAnswer.answerId : null;
                            selectedFormQuestion.answerIds = currentAnswer.answerIds ? currentAnswer.answerIds : [];
                            selectedFormQuestion.shortAnswer = currentAnswer.shortAnswer ? currentAnswer.shortAnswer : "";
                            formQuestions[questionIndex] = selectedFormQuestion;
                        }
                    }
                }
                if (questionInfo.questionType === 1) {
                    // set matching answers
                    const questionIndex = questions.findIndex(data => data.id === questionInfo.questionId);
                    if (questionIndex !== -1) {
                        const selectedFormQuestion = { ...formQuestions[questionIndex] };
                        selectedFormQuestion.attemptAnswers &&
                            selectedFormQuestion.attemptAnswers.length &&
                            selectedFormQuestion.attemptAnswers.forEach(attemptAnswer => {
                                selectedFormQuestion.matchingAnswers.push({ question: attemptAnswer.answerId, answer: attemptAnswer.wrongAnswerId ? attemptAnswer.wrongAnswerId : attemptAnswer.answerId })
                            })
                        formQuestions[questionIndex] = selectedFormQuestion;
                    }
                }
            });
            const timeLimit = response.data.timeLimit;
            const timeLimitEntity = response.data.timeLimitEntity;
            const startTime = this.searchParams.get(START_TIME_KEY) || attemptData.startTime;
            const gracePeriodEndDate = this.searchParams.get(GRACE_PERIOD_END_DATE_KEY);
            if (timeLimit && (timeLimitEntity || timeLimitEntity === 0 || startTime || gracePeriodEndDate)) {
                const dateNow = (startTime && MainService.convertUTCDateToLocalDate(new Date(startTime)))
                    || (gracePeriodEndDate && MainService.convertUTCDateToLocalDate(new Date(gracePeriodEndDate)))
                    || new Date();
                switch (timeLimitEntity) {
                    case 0:
                        dateNow.setSeconds(dateNow.getSeconds() + timeLimit);
                        break;
                    case 1:
                        dateNow.setMinutes(dateNow.getMinutes() + timeLimit);
                        break;
                    case 2:
                        dateNow.setHours(dateNow.getHours() + timeLimit);
                        break;
                    case 3:
                        dateNow.setDate(dateNow.getDate() + timeLimit);
                        break;
                    case 4:
                        dateNow.setDate(dateNow.getDate() + (timeLimit * 7));
                        break;
                    default:
                        break
                }
                // this.setCountDown(dateNow);
            }
            this.props.removePageSpinner(spinnerId);
            this.setState(prevState => ({
                ...prevState,
                questions,
                questionsInfo,
                checkedQuestionIds,
                canBeChecked: attemptData.canBeChecked,
                quizName: response.data.name,
                quizData: response.data,
                quizAttemptId: attemptData.id,
                form: {
                    ...prevState.form,
                    formQuestions
                }
            }));
        }).catch(e => this.getFail(spinnerId, e));
    }

    addQuestionsFormFields = (questions, quizAttemptId, questionsInfo) => {
        const formQuestions = [];
        questions.forEach(question => {
            const currentQuestionInfo = questionsInfo.find(data => data.questionId === question.id);
            formQuestions.push({
                quizAttemptId: quizAttemptId,
                questionId: question.id,
                questionType: question.questionType,
                answerId: null,
                isCorrect: null,
                shortAnswer: '',
                matchingAnswers: [],
                answerIds: [],
                passed: currentQuestionInfo && currentQuestionInfo.passed,
                isShuffle: currentQuestionInfo && currentQuestionInfo.isShuffle,
                choiceNumbering: currentQuestionInfo && currentQuestionInfo.choiceNumbering,
                tryAgain: currentQuestionInfo && currentQuestionInfo.tryAgain,
                mark: currentQuestionInfo && currentQuestionInfo.mark,
                markResponse: currentQuestionInfo && currentQuestionInfo.markResponse,
                whetherCorrect: currentQuestionInfo && currentQuestionInfo.whetherCorrect,
                hint: currentQuestionInfo && currentQuestionInfo.hint,
                generalFeedback: currentQuestionInfo && currentQuestionInfo.generalFeedbackResponse,
                attemptAnswers: currentQuestionInfo && currentQuestionInfo.attemptAnswers,
            });
        });
        return formQuestions;
    }

    openCurrentQuestion = currentQuestionIndex => {
        currentQuestionIndex !== -1 && this.setState({ currentQuestionIndex });
    }

    handlePageClick = (event) => {
        this.openCurrentQuestion(event.selected);
        this.setState({ activePageNumber: event.selected });
    }

    shuffle = prevAnswers => {
        const answers = [...prevAnswers];
        for (let i = answers.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = { ...answers[i] };
            answers[i] = { ...answers[j] };
            answers[j] = temp;
        }
        return answers;
    };

    getFail = (spinnerId, error) => {
        const { language, pureLayoutBackUrl } = this.props;
        this.props.removePageSpinner(spinnerId);
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
        error && pureLayoutBackUrl
            ? this.props.history.push(pureLayoutBackUrl)
            : this.props.history.push(`/${language}}`)
    };

    goBack = () => {
        window.history.back()
    };

    render() {
        const {
            questions,
            questionsInfo,
            currentQuestionIndex,
            canBeChecked,
            translationService,
            failedFields,
            isInvalidSubmit,
            invalidQuestionId,
            spinnerId,
            days,
            hours,
            minutes,
            seconds,
            isAttemptFinished,
            quizName,
            quizState,
            reviewAndSubmit,
            quizData,
            checkedQuestionIds,
            answerMaxLength,
            zoomImagePath,
            activePageNumber
        } = this.state;


        const { formQuestions } = this.state.form;
        const isQuizOverdue = quizState && quizState === 1;
        const isReview = isAttemptFinished || isQuizOverdue || reviewAndSubmit;

        return translationService &&
					questionsInfo.length &&
					questions.length ? (
					<div className="container">
						<div className="row">
							<div className="col-12 mt-3">
								<div className="content-title p-0">
									<div className="d-flex align-items-center">
										<Link
											to={`#`}
											title={translationService.translate("TR_BACK")}
											onClick={() => window.history.back()}>
											<ArrowBackSvg />
										</Link>
										<h2 className="content-title p-0">
											{translationService.translate("TR_QUIZ_REVIEW")}
										</h2>
									</div>
								</div>
								<PageInfoBlock
									pageTitle={translationService.translate("Quiz review")}
									pageName="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
									translationService={translationService}
								/>
							</div>
						</div>
						<hr />
						<div className="row">
							<div className="col-lg-3">
								<div className="background box-shadow-4 mb-lg-0 mb-3">
									<ul className="quiz-fildest-information">
										{quizData.timeLimit ? (
											<fieldset>
												<legend>
													{translationService.translate("TR_TIME_LIMIT")}
												</legend>
												<p>
													<span className="mr-1">{quizData.timeLimit}</span>
													{(() => {
														switch (quizData.timeLimitEntity) {
															case 0:
																return translationService.translate(
																	"TR_SECONDS",
																);
															case 1:
																return translationService.translate(
																	"TR_MINUTES",
																);
															case 2:
																return translationService.translate("TR_HOURS");
															case 3:
																return translationService.translate("TR_DAYS");
															case 4:
																return translationService.translate("TR_WEEKS");
															default:
																break;
														}
													})()}
												</p>
											</fieldset>
										) : null}
										{zoomImagePath ? (
											<div
												className="zoom-image-container"
												onClick={this.hideZoomImageVideo}>
												<div className="close-svg">
													<CloseSvg />
												</div>
												<img
													src={zoomImagePath}
													alt="/"
													onClick={event => event.stopPropagation()}
												/>
											</div>
										) : null}
										{quizData.openDate ? (
											<fieldset>
												<legend>
													{translationService.translate("TR_START_DATE")}
												</legend>
												<p>
													{moment(
														MainService.convertUTCDateToLocalDate(
															new Date(quizData.openDate),
														),
													).format("YYYY-MM-DD HH:mm:ss")}
												</p>
											</fieldset>
										) : null}
										{quizData.closeDate ? (
											<fieldset>
												<legend>
													{translationService.translate("TR_END_DATE")}
												</legend>
												<p>
													{moment(
														MainService.convertUTCDateToLocalDate(
															new Date(quizData.closeDate),
														),
													).format("YYYY-MM-DD HH:mm:ss")}
												</p>
											</fieldset>
										) : null}
										{quizData.questions && quizData.questions.length ? (
											<fieldset>
												<legend>
													{translationService.translate("TR_QUESTIONS_COUNT")}
												</legend>
												<p>{quizData.questions.length}</p>
											</fieldset>
										) : null}
										{quizData.gradingMethod || quizData.gradingMethod === 0 ? (
											<fieldset>
												<legend>
													{translationService.translate("TR_GRADING_METHOD")}
												</legend>
												<p>
													{translationService.translate(
														this.gradeingMethods[quizData.gradingMethod],
													)}
												</p>
											</fieldset>
										) : null}
									</ul>
								</div>
							</div>
							<div className="col-lg-9 col-12">
								<div className="background box-shadow-4 content position-relative overflow-hidden">
									<div className="content-title">
										<h3 className="content-title p-0 word-break-break-word">
											{quizName}
										</h3>
									</div>
									<hr />
									{reviewAndSubmit ? (
										<div className="content-title d-md-flex d-block justify-content-between pt-2 mb-2">
											<h6>
												{translationService.translate(
													"TR_REVIEW_QUESTIONS_AND_SUBMIT",
												)}
											</h6>
										</div>
									) : null}
									{
										// !isAttemptFinished && (days || hours || minutes || seconds)
										!isAttemptFinished &&
										quizData &&
										quizData.timeLimit &&
										(days || hours || minutes || seconds) ? (
											<Auxiliary>
												<hr />
												<div className="countdown-wrapper">
													<div className="countdown-item">
														<p className="timer-number">
															{days < 0 ? "0" : days}
														</p>
														<p className="time-name">
															{translationService.translate("TR_DAYS")}
														</p>
													</div>
													<div className="countdown-item">
														<p className="timer-number">
															{hours < 0 ? "0" : hours}
														</p>
														<p className="time-name">
															{translationService.translate("TR_HOURS")}
														</p>
													</div>
													<div className="countdown-item">
														<p className="timer-number">
															{minutes < 0 ? "0" : minutes}
														</p>
														<p className="time-name">
															{translationService.translate("TR_MINUTES")}
														</p>
													</div>
													<div className="countdown-item">
														<p className="timer-number">
															{seconds < 0 ? "0" : seconds}
														</p>
														<p className="time-name">
															{translationService.translate("TR_SECONDS")}
														</p>
													</div>
												</div>
												<hr />
											</Auxiliary>
										) : null
									}
									{/* after  finish attempt*/}
									{questionsInfo &&
										questionsInfo.map((questionInfo, questionInfoIndex) => {
											const question = questions.find(
												data => data.id === questionInfo.questionId,
											);
											const questionIndex = questions.findIndex(
												data => data.id === questionInfo.questionId,
											);
											if (
												(questionIndex === -1 ||
													questionIndex !== currentQuestionIndex) &&
												!isReview
											) {
												return false;
											}
											const {
												isCorrect,
												shortAnswer,
												matchingAnswers,
												answerIds,
												answerId,
												passed,
												mark,
												markResponse,
												whetherCorrect,
												hint,
												generalFeedback,
												attemptAnswers,
												tryAgain,
												choiceNumbering,
											} = formQuestions[questionIndex];

											const isDisabled =
												passed ||
												tryAgain ||
												isAttemptFinished ||
												isQuizOverdue ||
												isReview ||
												reviewAndSubmit;
											const canTryAgain =
												!isAttemptFinished && tryAgain && !isQuizOverdue;
											const markMessage = mark
												? translationService
														.translate("TR_QUESTION_MARK_MSG")
														.replace("{0}", markResponse)
														.replace("{1}", mark)
												: null;

											return (
												<Auxiliary
													key={`${question.id}_${question.languageId}`}>
													<div className="content-body py-0">
														<div className="question-wrapper">
															{/* <p className="question-name">{question.name}</p>
                        <p className="disable-user-select">{question.text}</p> */}

															<span className="question-dropdown-block">
																<span className="question-name">
																	{Parser(question.name)}
																</span>
															</span>

															<span className="question-dropdown-block">
																<span className="disable-user-select">
																	{Parser(question.text)}
																</span>
															</span>

															<p className="question-options">
																{checkedQuestionIds.find(
																	id => id === question.id,
																) ? (
																	<Auxiliary>
																		<span>
																			{translationService.translate(
																				"TR_ANSWER_SAVED",
																			)}
																		</span>
																	</Auxiliary>
																) : null}
																<span>
																	{markResponse || markResponse === 0
																		? markMessage
																		: null}
																</span>
															</p>
														</div>
														<hr />
														<form className="question-form">
															<div className="row">
																<div className="col-12">
																	{(() => {
																		switch (question.questionType) {
																			case 0: // Multi
																				return (
																					question.questionAnswers &&
																					question.questionAnswers.map(
																						(
																							questionAnswer,
																							questionAnswersIndex,
																						) => {
																							const currentAnswerAttempt =
																								attemptAnswers &&
																								attemptAnswers.find(
																									data =>
																										data.answerId ===
																										questionAnswer.id,
																								);
																							return (
																								<div
																									key={questionAnswer.id}
																									className={`question-body-container custom-inp-style pl-0 custom-control
                                              ${
																								question.isMultiAnswer
																									? "custom-checkbox"
																									: "custom-radio"
																							}
                                            `}>
																									{question.isMultiAnswer ? (
																										<input
																											type="checkbox"
																											id={`multi_${questionAnswer.id}`}
																											name="answerIds"
																											disabled={isDisabled}
																											checked={
																												(currentAnswerAttempt
																													? currentAnswerAttempt.answerId
																													: null) ||
																												answerIds.includes(
																													questionAnswer.id,
																												)
																											}
																											className={`custom-control-input ${
																												isInvalidSubmit &&
																												invalidQuestionId ===
																													question.id &&
																												!answerIds.length
																													? "is-invalid error-bordered"
																													: ""
																											}`}
																											onChange={() => {}}
																										/>
																									) : (
																										<input
																											type="radio"
																											id={`multi_${questionAnswer.id}`}
																											name="answerId"
																											value={questionAnswer.id}
																											disabled={isDisabled}
																											checked={
																												questionAnswer.id ===
																													answerId ||
																												questionAnswer.id ===
																													(currentAnswerAttempt &&
																														currentAnswerAttempt.answerId)
																											}
																											className={`custom-control-input ${
																												isInvalidSubmit &&
																												invalidQuestionId ===
																													question.id &&
																												!answerId
																													? "is-invalid error-bordered"
																													: ""
																											}`}
																											onChange={() => {}}
																										/>
																									)}
																									<label
																										htmlFor={`multi_${questionAnswer.id}`}
																										className={`custom-control-label question-label-wrapper
                                               ${
																									currentAnswerAttempt &&
																									currentAnswerAttempt.isCorrect !==
																										null
																										? currentAnswerAttempt.isCorrect
																											? "light-green-background"
																											: "light-red-background"
																										: "light-gray-background"
																								}
                                            `}>
																										{choiceNumbering ? (
																											<p className="question-number">
																												{(() => {
																													switch (
																														choiceNumbering
																													) {
																														case 1:
																															return this
																																.alphabet[
																																questionAnswersIndex
																															];
																														case 2:
																															return this.alphabet[
																																questionAnswersIndex
																															].toUpperCase();
																														case 3:
																															return (
																																questionAnswersIndex +
																																1
																															);
																														default:
																															break;
																													}
																												})()}
																												.
																											</p>
																										) : null}
																										<div className="d-flex question-body">
																											<span
																												className={`form-check-label
                                                    ${
																											currentAnswerAttempt &&
																											currentAnswerAttempt.isCorrect !==
																												null
																												? currentAnswerAttempt.isCorrect
																													? "green-color"
																													: "red-color"
																												: "gray-color"
																										}
                                                     ${
																												isInvalidSubmit &&
																												invalidQuestionId ===
																													question.id &&
																												(question.isMultiAnswer
																													? !answerIds.length
																													: !answerId)
																													? "fail"
																													: ""
																											}
                                                    `}>
																												<span className="question-dropdown-block">
																													<span className="question-name pl-1 d-flex">
																														{Parser(
																															questionAnswer.text,
																														)}
																													</span>
																												</span>
																											</span>
																										</div>
																									</label>
																									{currentAnswerAttempt &&
																									currentAnswerAttempt.specificFeedback ? (
																										<div className="question-feedback-container light-blue-background">
																											<span className="form-check-label blue-color">
																												<span className="question-dropdown-block">
																													{Parser(
																														currentAnswerAttempt.specificFeedback,
																													)}
																												</span>
																											</span>
																										</div>
																									) : null}
																								</div>
																							);
																						},
																					)
																				);
																			case 1: // Matching
																				return (
																					<Auxiliary>
																						{question.matchingQuestionAnswers &&
																							question.matchingQuestionAnswers.map(
																								matchingQuestionAnswer => {
																									const questionAnswers = [
																										...question.questionAnswers,
																									];
																									// questionAnswers.forEach(questionAnswer => {
																									//   questionAnswer.text = this.convertHtmeToString(questionAnswer.text);
																									//   questionAnswer.question = this.convertHtmeToString(questionAnswer.question);
																									// })
																									const currentAnswerAttempt =
																										attemptAnswers &&
																										attemptAnswers.find(
																											data =>
																												data.answerId ===
																												matchingQuestionAnswer.id,
																										);
																									const currentMatchingQuestion =
																										matchingAnswers &&
																										matchingAnswers.find(
																											data =>
																												data.question ===
																												matchingQuestionAnswer.id,
																										);

																									return (
																										<div
																											key={
																												matchingQuestionAnswer.id
																											}
																											className="question-body-container">
																											<div className="row mb-4 mt-2">
																												<div
																													className={`col-md-10`}>
																													<div className="row">
																														<div className="col-md-6 col-12">
																															<fieldset>
																																<legend>
																																	{translationService.translate(
																																		"TR_QUESTION",
																																	)}
																																</legend>
																																<div
																																	className={`question-label-wrapper m-0 flex-column
                                                        ${
																													currentAnswerAttempt &&
																													currentAnswerAttempt.isCorrect !==
																														null
																														? currentAnswerAttempt.isCorrect
																															? "light-green-background"
																															: "light-red-background"
																														: "light-gray-background"
																												}
                                                            ${
																															isInvalidSubmit &&
																															invalidQuestionId ===
																																question.id &&
																															!currentMatchingQuestion
																																? "fail"
																																: ""
																														}`}>
																																	<span
																																		className={`word-break-break-word
                                                            ${
																															currentAnswerAttempt &&
																															currentAnswerAttempt.isCorrect !==
																																null
																																? currentAnswerAttempt.isCorrect
																																	? "green-color"
																																	: "red-color"
																																: "gray-color"
																														}
                                                            ${
																															isInvalidSubmit &&
																															invalidQuestionId ===
																																question.id &&
																															!currentMatchingQuestion
																																? "fail"
																																: ""
																														}
                                                            `}>
																																		{/* {matchingQuestionAnswer.question} */}
																																		{/* Question */}
																																		<span className="question-dropdown-block">
																																			{Parser(
																																				matchingQuestionAnswer.question,
																																			)}
																																		</span>
																																	</span>
																																</div>
																															</fieldset>
																														</div>
																														<div className="col-md-6 col-12">
																															<fieldset className="min-width-auto">
																																<legend>
																																	{translationService.translate(
																																		"TR_ANSWER",
																																	)}
																																</legend>
																																<div className="question-label-wrapper m-0 flex-column">
																																	{questionAnswers.map(
																																		questionAnswer => {
																																			var a =
																																				(currentMatchingQuestion &&
																																					currentMatchingQuestion.answer) ||
																																				(currentAnswerAttempt &&
																																					(currentAnswerAttempt.wrongAnswerId
																																						? currentAnswerAttempt.wrongAnswerId
																																						: currentAnswerAttempt.answerId));
																																			if (
																																				a ===
																																				questionAnswer.id
																																			) {
																																				return (
																																					<Auxiliary
																																						key={
																																							questionAnswer.id
																																						}>
																																						<span className="question-dropdown-block">
																																							{Parser(
																																								questionAnswer.text,
																																							)}
																																						</span>
																																					</Auxiliary>
																																				);
																																			}
																																		},
																																	)}
																																</div>
																															</fieldset>
																														</div>
																													</div>
																													{currentAnswerAttempt &&
																													currentAnswerAttempt.specificFeedback ? (
																														<div className="question-feedback-container light-blue-background">
																															<span className="form-check-label blue-color">
																																{/* {currentAnswerAttempt.specificFeedback} */}
																																<span className="question-dropdown-block">
																																	{Parser(
																																		currentAnswerAttempt.specificFeedback,
																																	)}
																																</span>
																															</span>
																														</div>
																													) : null}
																												</div>

																												<div className="col-md-2 mt-3">
																													<div className="w-100 d-flex justify-content-end">
																														<div
																															className={`btn-group dropleft ${
																																isDisabled
																																	? "disabled"
																																	: ""
																															}`}>
																															{/* <button
                                                                                                            className={`btn mindalay--btn-default mindalay--btn-small dropdown-toggle ${isInvalidSubmit && invalidQuestionId === question.id && !currentMatchingQuestion
                                                                                                                ? "is-invalid error-bordered" : ""} `}
                                                                                                            type="button"
                                                                                                            id="dropdownMenuButton"
                                                                                                            data-toggle="dropdown"
                                                                                                            aria-haspopup="true"
                                                                                                            aria-expanded="false"
                                                                                                        >
                                                                                                            {`${translationService.translate("TR_CHOOSE")}...`}
                                                                                                        </button> */}
																															<div
																																className="dropdown-menu box-shadow-33 question-answer-dropdown"
																																aria-labelledby="dropdownMenuButton"
																																// style={{ maxWidth: `${this.windowWidth}px` }}
																															>
																																{questionAnswers.map(
																																	(
																																		questionAnswer,
																																		index,
																																	) => {
																																		return (
																																			<div
																																				className="answer"
																																				key={
																																					index
																																				}>
																																				<Link
																																					to="#"
																																					className="dropdown-item"
																																					onClick={() => {}}>
																																					<span className="question-dropdown-block">
																																						{Parser(
																																							questionAnswer.text,
																																						)}
																																					</span>
																																				</Link>
																																				<hr className="m-0" />
																																			</div>
																																		);
																																	},
																																)}
																															</div>
																														</div>
																													</div>
																												</div>
																											</div>
																										</div>
																									);
																								},
																							)}
																					</Auxiliary>
																				);
																			case 2: // Short
																				const hasAttemptShortAnswer =
																					attemptAnswers && attemptAnswers[0];
																				return (
																					<div className="question-body-container">
																						<div
																							className={`question-label-wrapper
                                        ${
																					hasAttemptShortAnswer &&
																					attemptAnswers[0].isCorrect !== null
																						? attemptAnswers[0].isCorrect
																							? "light-green-background"
																							: "light-red-background"
																						: "light-gray-background"
																				}`}>
																							<p
																								className={`mr-2 ${
																									hasAttemptShortAnswer &&
																									attemptAnswers[0]
																										.isCorrect !== null
																										? attemptAnswers[0]
																												.isCorrect
																											? "green-color"
																											: "red-color"
																										: "gray-color"
																								}`}>
																								{translationService.translate(
																									"TR_ANSWER",
																								)}
																								:
																							</p>
																							<Textarea
																								type="text"
																								id="shortAnswer"
																								name="shortAnswer"
																								blockClassName="mb-0 w-100"
																								textAreaClassName="pr--5"
																								rows="3"
																								max={answerMaxLength}
																								placeholder={`${translationService.translate(
																									"TR_TYPE",
																								)}...`}
																								value={
																									(attemptAnswers &&
																										attemptAnswers[0] &&
																										attemptAnswers[0]
																											.shortAnswer) ||
																									shortAnswer
																								}
																								disabled={isDisabled}
																								isInvalidSubmit={
																									isInvalidSubmit &&
																									invalidQuestionId ===
																										question.id
																								}
																								onChange={() => {}}
																								failedFields={failedFields}
																							/>
																						</div>
																						{attemptAnswers &&
																						attemptAnswers[0] &&
																						attemptAnswers[0]
																							.specificFeedback ? (
																							<div className="question-feedback-container light-blue-background">
																								<p className="form-check-label blue-color">
																									{Parser(
																										attemptAnswers[0]
																											.specificFeedback,
																									)}
																								</p>
																							</div>
																						) : null}
																					</div>
																				);
																			case 3: // True-False
																				const hasAttemptTrueFalseAnswer =
																					attemptAnswers && attemptAnswers[0];
																				return (
																					<Auxiliary>
																						<div className="question-body-container">
																							<div
																								className={`question-label-wrapper ${
																									hasAttemptTrueFalseAnswer &&
																									attemptAnswers[0]
																										.trueFalse === true &&
																									attemptAnswers[0]
																										.isCorrect !== null
																										? attemptAnswers[0]
																												.isCorrect
																											? "light-green-background"
																											: "light-red-background"
																										: "light-gray-background"
																								}`}>
																								<InputRadio
																									id="trueVal"
																									name="isCorrect"
																									value={true}
																									disabled={isDisabled}
																									checked={
																										isCorrect === true ||
																										(attemptAnswers &&
																											attemptAnswers[0] &&
																											attemptAnswers[0]
																												.trueFalse === true)
																									}
																									labelValue={translationService.translate(
																										"TR_TRUE",
																									)}
																									labelClassName={
																										isInvalidSubmit &&
																										invalidQuestionId ===
																											question.id &&
																										isCorrect === null
																											? "is-invalid fail"
																											: ""
																									}
																									radioClassName={
																										isInvalidSubmit &&
																										invalidQuestionId ===
																											question.id &&
																										isCorrect === null
																											? "is-invalid fail"
																											: ""
																									}
																									blockClassName="mb-1 mr-3"
																									onChange={() => {}}
																								/>
																							</div>
																							{attemptAnswers &&
																							attemptAnswers[0] &&
																							attemptAnswers[0].trueFalse ===
																								true &&
																							attemptAnswers[0]
																								.specificFeedback ? (
																								<div className="question-feedback-container light-blue-background">
																									<p className="form-check-label blue-color">
																										{/* {attemptAnswers[0].specificFeedback} */}
																										{Parser(
																											attemptAnswers[0]
																												.specificFeedback,
																										)}
																									</p>
																								</div>
																							) : null}
																						</div>
																						<div className="question-body-container">
																							<div
																								className={`question-label-wrapper ${
																									hasAttemptTrueFalseAnswer &&
																									attemptAnswers[0]
																										.trueFalse === false &&
																									attemptAnswers[0]
																										.isCorrect !== null
																										? attemptAnswers[0]
																												.isCorrect
																											? "light-green-background"
																											: "light-red-background"
																										: "light-gray-background"
																								}`}>
																								<InputRadio
																									id="falseVal"
																									name="isCorrect"
																									value={false}
																									disabled={isDisabled}
																									checked={
																										isCorrect === false ||
																										(attemptAnswers &&
																											attemptAnswers[0] &&
																											attemptAnswers[0]
																												.trueFalse === false)
																									}
																									labelClassName={
																										isInvalidSubmit &&
																										invalidQuestionId ===
																											question.id &&
																										isCorrect === null
																											? "is-invalid fail"
																											: ""
																									}
																									radioClassName={
																										isInvalidSubmit &&
																										invalidQuestionId ===
																											question.id &&
																										isCorrect === null
																											? "is-invalid fail"
																											: ""
																									}
																									labelValue={translationService.translate(
																										"TR_FALSE",
																									)}
																									onChange={() => {}}
																								/>
																							</div>
																							{attemptAnswers &&
																							attemptAnswers[0] &&
																							attemptAnswers[0].trueFalse ===
																								false &&
																							attemptAnswers[0]
																								.specificFeedback ? (
																								<div className="question-feedback-container light-blue-background">
																									<p className="form-check-label blue-color">
																										{/* {attemptAnswers[0].specificFeedback} */}
																										{Parser(
																											attemptAnswers[0]
																												.specificFeedback,
																										)}
																									</p>
																								</div>
																							) : null}
																						</div>
																					</Auxiliary>
																				);
																			default:
																				break;
																		}
																	})()}
																	{(() => {
																		let fieldName = "";
																		switch (question.questionType) {
																			case 0: //Multi
																				fieldName = question.isMultiAnswer
																					? "answerIds"
																					: "answerId";
																				break;
																			case 1: //Matching
																				fieldName = "matchingAnswers";
																				break;
																			case 2: //Short
																				fieldName = "shortAnswer";
																				break;
																			case 3: //TrueFalse
																				fieldName = "isCorrect";
																				break;
																			default:
																				break;
																		}
																	})()}
																</div>
															</div>
														</form>
														{isAttemptFinished || isReview ? null : (
															<Auxiliary>
																<hr />
																<div className="content-header-btn-group d-sm-flex d-block justify-content-between align-items-center">
																	<div className="pagination-location ml-2 d-none d-sm-block">
																		<span>{currentQuestionIndex + 1}</span>
																		<span>/</span>
																		<span>{questionsInfo.length}</span>
																	</div>

																	<div className="pagination-group d-flex justify-content-center my-2">
																		<ReactPaginate
																			nextLabel={translationService.translate(
																				"TR_NEXT",
																			)}
																			onPageChange={this.handlePageClick}
																			pageRangeDisplayed={2}
																			marginPagesDisplayed={2}
																			pageCount={questionsInfo.length}
																			previousLabel={translationService.translate(
																				"TR_PREVIOUS",
																			)}
																			pageClassName="page-item"
																			pageLinkClassName="page-link"
																			previousClassName="page-item"
																			previousLinkClassName="page-link"
																			nextClassName="page-item"
																			nextLinkClassName="page-link"
																			breakLabel="..."
																			breakClassName="page-item"
																			breakLinkClassName="page-link"
																			containerClassName="pagination"
																			activeClassName="active"
																			renderOnZeroPageCount={null}
																			forcePage={currentQuestionIndex}
																		/>
																	</div>

																	<div className="d-flex justify-content-between align-items-center">
																		<div className="pagination-location ml-2 d-block d-sm-none">
																			<span>{currentQuestionIndex + 1}</span>
																			<span>/</span>
																			<span>{questionsInfo.length}</span>
																		</div>

																		<div className="pagination-dropdown pt-2">
																			<DataListOption
																				id="paginationdropdown"
																				type="text"
																				containerClasses="m-0"
																				inputClasses="form-control-padding"
																				currentPage={currentQuestionIndex + 1}
																				items={questionsInfo}
																				changed={value => {
																					this.openCurrentQuestion(+value - 1);
																				}}
																			/>
																		</div>
																	</div>
																</div>
															</Auxiliary>
														)}
														{whetherCorrect || hint || generalFeedback ? (
															<div className="question-form">
																<hr />
																<div className="question-body-container">
																	{whetherCorrect ? (
																		<div className="question-label-wrapper light-blue-background blue-border mt-3">
																			<span className="question-dropdown-block">
																				<span className="blue-text-color word-break-break-word">
																					{" "}
																					{Parser(whetherCorrect)}
																				</span>
																			</span>
																		</div>
																	) : null}
																	{hint ? (
																		<div className="question-label-wrapper light-blue-background blue-border mt-3">
																			<span className="question-dropdown-block">
																				<span className="blue-text-color word-break-break-word">
																					{Parser(hint)}
																				</span>
																			</span>
																		</div>
																	) : null}
																	{generalFeedback ? (
																		<div className="question-label-wrapper light-blue-background blue-border mt-3">
																			<span className="question-dropdown-block">
																				<span className="blue-text-color word-break-break-word">
																					{Parser(generalFeedback)}
																				</span>
																			</span>
																		</div>
																	) : null}
																</div>
															</div>
														) : null}
													</div>
												</Auxiliary>
											);
										})}
								</div>
							</div>
						</div>
					</div>
				) : null;
    }
}

const mapStateToProps = state => ({
    translations: state.translation.translations,
    language: state.language.language,
    languages: state.language.languages,
    pureLayoutBackUrl: state.main.pureLayoutBackUrl,
    user: state.user.user
});

const mapDispatchToProps = {
    addPageSpinner,
    removePageSpinner,
    addButtonSpinner,
    removeButtonSpinner
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(QuizAttempt);